import React, { useState } from "react";

import { theme } from "antd";
import { DeleteOutlined } from '@ant-design/icons'
import Modal from "antd/es/modal/Modal";
import { useTranslation } from 'react-i18next';
import { BasicApi } from "../../../API/basicApi";

const { useToken } = theme

/**
 * 
 * @param {*} param0 
 * @returns html of a column element
 */
const RemoveButton = ({ element,route, onDelete }) => {

    const { t } = useTranslation()
    const { token } = useToken()

    // Delete an element modal view
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    function openDeleteModal() {
        setIsDeleteModalOpen(true);
    };

    async function handleDeleteOk(element) {
        setIsDeleteModalOpen(false);
        // Add logic to call the API to delete the user
        const api = new BasicApi(route)
        await api.deleteOne(element.id)
        onDelete && onDelete(element)
    };

    function handleDeleteCancel() {
        setIsDeleteModalOpen(false);
    };
    // -------------------------------

    return (
        <>
            <DeleteOutlined style={{ "fontSize": "large", "color": token.colorError }} onClick={openDeleteModal} />
            <Modal title="Delete Element" open={isDeleteModalOpen} onOk={() => handleDeleteOk(element)} onCancel={handleDeleteCancel}>
                <p>{t('Are you sure you want to remove this element?')}</p>
                <p>{t("Name")}: {element.name}</p>
                {/* <p>{t("MAC")}: {element.networkServerMac}</p> */}
            </Modal>
        </>
    )

};

export default RemoveButton;
