import { ENV } from "../../utils"

export const columns = [
  {
    name: 'Nombre',
    key: 'name',
    filter: 'text',
  },
  {
    name: 'Icono',
    key: 'icon',
    as: 'img'
  },
]

export const categories_associations_props = {
  route:`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_ASSOCIATIONS}`,
  title: 'Categories Associations',
  entity: 'categoriesAssociations',
  columns: columns,
  actions: ['edit', 'remove'],
  inputAttributes: [
    {
      name: 'Nombre',
      key: 'name',
      required: true
    },
  ],
  hasRowSelection: false,
  data: []
}