import http from '../API/http-common'
import { ENV } from '../utils'

class AuthService {
  async register(data) {
    const url = `${ENV.BASE_API}/${ENV.API_ROUTES.REGISTER}`

    try {
      const { data: result } = await http.post(url, data)
      return result
    } catch (error) {
      throw error.response.data
    }
  }

  async login(data) {
    const url = `${ENV.BASE_API}/${ENV.API_ROUTES.LOGIN}`

    try {
      const { data: result } = await http.post(url, data)
      return result
    } catch (error) {
      throw error.response.data
    }
  }
}
export default new AuthService()
