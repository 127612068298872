import { Link } from 'react-router-dom'
import { useAuth } from '../../Hooks/index'
import { Space, Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import '../../styles/App.css'
import LeftMenuHooks from '../LeftMenu/LeftMenuHooks.hooks'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo/logo.png'
import { useSearchContext } from '../../Hooks/useSearchContext'
import debounce from 'lodash.debounce'

function NavBar() {
  const navigate = useNavigate()
  const searchContext = useSearchContext()
  const [search, setSearch] = useState('')

  const { toggleMenu } = LeftMenuHooks()

  const { isAuthenticated, logout } = useAuth()

  const { t } = useTranslation()

  const logoutSession = () => {
    navigate('/')
    logout()
  }
  const handleSearchButton = () => {
    searchContext.setSearch(search)
  }
  const handleSearch = (search) => {
    setSearch(search)
    searchContext.setSearch(search)
  }
  const debouncedHandleSearch = debounce(handleSearch, 300)

  return (
    <nav style={{ backgroundColor: '#C5C4C4', zIndex: 1 }} className='navbar navbar-expand-lg navbar-light fixed-top'>
      <div className='d-flex justify-content-between ' style={{ width: '100%', margin: '0 5rem' }}>
        <div
          className='navbar-brand burger-menu'
          onClick={() => {
            toggleMenu()
          }}
        >
          <i className='bi bi-list'></i>
        </div>
        <Link className='navbar-brand' to={'/'}>
          <img src={logo} width='100' alt='LOGO OCITY'></img>
        </Link>
        <div className='collapse navbar-collapse' id='navbarTogglerDemo02'>
          <ul className='navbar-nav ml-auto'>
            <Space size={23}>
              <div className='search-bar'>
                <Input type='text' placeholder='Buscar...' onChange={(e) => debouncedHandleSearch(e.target.value)} />
                <Button type='button' className='buttonPrimary' onClick={handleSearchButton}>
                  Buscar
                </Button>
              </div>

              {isAuthenticated ? (
                <>
                  <Link className='nav-link' onClick={logoutSession}>
                    Logout
                  </Link>
                </>
              ) : (
                <Link className='nav-link' to={'/sign-in'}>
                  {t('Login')}
                </Link>
              )}
            </Space>
          </ul>
        </div>
      </div>
    </nav>
  )
}
export default NavBar
