
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom"
// Estilos
import styles from './LeftMenu.module.css'
import SubMenu from './SubMenu/SubMenu.component';
import LeftMenuHooks from './LeftMenuHooks.hooks';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../ConfirmModal/index.component'
import logo from '../../assets/logo/logo.png';
import catedra_logo from '../../assets/logo/catedra.png';
import colaboracion_logo from '../../assets/logo/sma.png';
import colaboracion_logo_abroad from '../../assets/logo/abroad.png';
import colaboracion_logo_ysolve from '../../assets/logo/ysolve.png';
import comunitat_logo from '../../assets/logo/subvencion.png';
import caixa_logo from '../../assets/logo/caixa.png';

import { useAuth } from "../../Hooks/index";
import { PasaLaBola, catedra, subvencion, colaboracion, colaboracion_ysolve, colaboracion_abroad } from './ExternalRedirects';

const LeftMenu = () => {
    const location = useLocation()

    const { isAuthenticated } = useAuth();

    const [activated, setActivated] = useState('Escritorio')
    const { hide } = LeftMenuHooks();

    const { t } = useTranslation()

    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisibleByImg, setModalVisibleByImg] = useState(false);
    const handleModalCancelOnLearn = () => {
        setModalVisible(false);
    };

    const handleModalConfirmOnLearn = () => {
        // setModalVisible(false);
        if (activated === "PasaLaBola")
            window.location.assign(PasaLaBola);
        else if (activated === "catedra")
            window.location.assign(catedra);
    };

    const validateMobile = () => {
        if (window.innerWidth <= 750) {
            hide()
        }
    }

    return (
        <>
            <div id="backdrop" className={[styles.backdrop]} onClick={() => hide()}></div>
            <div id="leftmenu">
                <div className={[styles.mainContainer].join(' ')}>
                    <div className={styles.containerLogo}>
                        <img src={logo} alt="Promis Logo"></img>
                    </div>
                    {isAuthenticated ? <>
                        <h5> [ Modo Administrador ]</h5>
                        <Link onClick={() => validateMobile()} to='/'>
                            <SubMenu title={t('Home')} icon='bi bi-house-fill'
                                setActivated={() => { setActivated("Home"); hide() }}
                                linkActivated={activated === "Home" ? true : false}
                                location={location.pathname}
                            /></Link>
                        <div className={styles.separator}></div>

                        <Link onClick={() => validateMobile()} to='/dashboard/user'>
                            <SubMenu title={t("Users")} icon='bi bi-user-fill'
                                setActivated={() => { setActivated("Users"); hide() }}
                                linkActivated={activated === "Users" ? true : false}
                                location={location.pathname}
                            /></Link>
                        <Link onClick={() => validateMobile()} to='/dashboard/categories/awards'>
                            <SubMenu title={"AwardsCategories"} icon='bi bi-user-fill'
                                setActivated={() => { setActivated("AwardsCategories"); hide() }}
                                linkActivated={activated === "AwardsCategories" ? true : false}
                                location={location.pathname}
                            /></Link>
                        <Link onClick={() => validateMobile()} to='/dashboard/awards'>
                            <SubMenu title={"Awards"} icon='bi bi-user-fill'
                                setActivated={() => { setActivated("Awards"); hide() }}
                                linkActivated={activated === "Awards" ? true : false}
                                location={location.pathname}
                            /></Link>
                        <Link onClick={() => validateMobile()} to='/dashboard/associations'>
                            <SubMenu title={"Associations"} icon='bi bi-user-fill'
                                setActivated={() => { setActivated("Associations"); hide() }}
                                linkActivated={activated === "Associations" ? true : false}
                                location={location.pathname}
                            /></Link>
                        <Link onClick={() => validateMobile()} to='/dashboard/categories/associations'>
                            <SubMenu title={"AssociationsCategories"} icon='bi bi-user-fill'
                                setActivated={() => { setActivated("AssociationsCategories"); hide() }}
                                linkActivated={activated === "AssociationsCategories" ? true : false}
                                location={location.pathname}
                            /></Link>
                        <div className={styles.separator}></div>
                    </> :
                        <></>
                    }
                    {/* <Link to='#' onClick={handleLinkClickOnLearn} > <SubMenu title={"Pasa La Bola"} icon='bi bi-heart-pulse-fill' */}
                    <Link to='#' onClick={() => { window.location.assign(PasaLaBola); }} > <SubMenu title={"Pasa La Bola"} icon='bi bi-heart-pulse-fill'
                        setActivated={() => { setActivated("PasaLaBola"); }}
                        linkActivated={activated === "PasaLaBola" ? true : false}
                        location={location.pathname}
                    /></Link>

                    {!isAuthenticated ? <>
                        <p> Patrocinan:</p>
                        <div>
                            <img src={catedra_logo} width={"170px"} style={{ margin: '10px', cursor: 'pointer' }} onClick={() => { window.location.href = catedra }} alt="Catedra de innovación Logo"></img>
                            <img src={colaboracion_logo_ysolve} width={"70px"} style={{ margin: '10px', cursor: 'pointer', alignSelf: "center" }} onClick={() => { window.location.href = colaboracion_ysolve }} alt="Ysolve Logo"></img>
                        </div>
                        <div>
                            <p> Colaboración con:</p>
                        <div>
                            <img src={caixa_logo} width={"100px"} style={{ margin: '10px', cursor: 'pointer', alignSelf: "center" }} onClick={() => { window.location.href = colaboracion_ysolve }} alt="Ysolve Logo"></img>
                        </div>
                            <img src={colaboracion_logo} width={"100px"} style={{ margin: '10px', cursor: 'pointer', alignSelf: "center" }} onClick={() => { window.location.href = colaboracion }} alt="SMA Logo"></img>
                            <img src={colaboracion_logo_abroad} width={"100px"} style={{ margin: '10px', cursor: 'pointer', alignSelf: "center" }} onClick={() => { window.location.href = colaboracion_abroad }} alt="SMA Logo"></img>
                        </div>
                        <p> Subvencionado por:</p>
                        <img src={comunitat_logo} style={{ margin: '10px', cursor: 'pointer' }} onClick={() => { window.location.href = subvencion }} alt="GVA Logo"></img>


                    </> : <></>
                    }
                    <ConfirmModal
                        visible={modalVisible}
                        onCancel={handleModalCancelOnLearn}
                        onConfirm={handleModalConfirmOnLearn}
                        haveForm={activated === "Support" ? true : false}
                    />
                    <div className={styles.exitBtn} onClick={() => { hide() }}>
                        <i className="bi bi-x-lg"></i>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LeftMenu;
