import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar.component';
import LeftMenu from '../../components/LeftMenu/LeftMenu.component';
import DashboardRoutes from '../DashboardRoutes.component';

function DashboardRouter() {
  return (
    <div className="dashboard-container" style={{height:"100%"}}>
      <BrowserRouter>
        <NavBar />
        <LeftMenu />
        <DashboardRoutes />
        
      </BrowserRouter>
    </div>
  );
}

export default DashboardRouter;
