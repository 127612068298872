import { ENV } from '../../utils'
import React, { useEffect, useState, useRef } from 'react'

import { Col, Row, Space, Table, theme, Tooltip, Typography, Modal, Button, Breadcrumb, DatePicker, Input } from 'antd'
import {
  SettingOutlined,
  LinkOutlined,
  PlusOutlined,
  CheckCircleFilled,
  ClockCircleFilled,
  SearchOutlined,
  ExclamationCircleFilled,
  CalendarOutlined,
} from '@ant-design/icons'
import RemoveButton from './RemoveButton'
import EditButton from './EditButton'
import ContentButton from './ContentButton'
import MqttLogButton from './MqttLogButton'
import ServerButton from './ServerButton'
import { useTranslation } from 'react-i18next'
import { useBreadcrumbs, useBreadcrumbsUpdate } from '../../Hooks/useBreadcrumbs'
import { useLocation } from 'react-router-dom'
import UpdateItemForm from '../UpdateItemForm'
import LockButton from './LockButton'
import HistoryListButton from './HistoryListButton'
import CalendarButton from './CalendarButton'
import Highlighter from 'react-highlight-words'
import { CSVLink } from 'react-csv'
import AwardsForm from '../../pages/Awards/components/awardsForm.component'
import AssociationForm from '../../pages/Associations/components/associationsForm.component'
import CategoriesAssociationsForm from '../../pages/CategoriesAssociations/components/categoriesAssociationsForm.component'
import CategoriesAwardsForm from '../../pages/CategoriesAwards/components/categoriesAwardsForm.component'
import UserForm from '../../pages/Users/components/userForm.component'
import { PAGINATION_SIZE } from '../../utils'

const { useToken } = theme
const { Title } = Typography

/**
 * Creates a Table component
 * @param {*}
 * { title: string, columns: array of {key, name} to show, actions: array with action names, data: array of {key, name} to show, inputAttributes: array of {key, name} to edit element}
 * @returns
 */
const defaultProps = {
  title: 'My Table',
  columns: [
    {
      name: 'Name',
      key: 'name',
      filter: 'text',
    },
    {
      name: 'Phone',
      key: 'name',
      filter: 'none',
    },
  ],
  actions: ['edit', 'remove'],
  data: [],
  inputAttributes: [],
  rowsPerPage: 10,
  total: 10,
  hasRowSelection: false,
}

const CommonTable = ({ props }) => {
  const tableTitle = props.title ?? defaultProps.title
  const tableActions = props.actions ?? defaultProps.actions
  const tableCols = props.columns ?? defaultProps.columns
  const elementInputAttributes = props.inputAttributes ?? defaultProps.inputAttributes
  const tableData = props.data ?? defaultProps.data
  const tableHasRowSelection = props.hasRowSelection ?? defaultProps.hasRowSelection
  const onDelete = props.onDelete
  const onAdd = props.onAdd
  const onUpdate = props.onUpdate
  const selectionType = 'checkbox'

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGINATION_SIZE,
    total: null,
  })

  useEffect(() => {
    setPagination((prevState) => ({ ...prevState, total: props.total }))
  }, [props])

  const { token } = useToken()
  const { t } = useTranslation()
  const location = useLocation()

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)

  const updateBreadcrumbs = useBreadcrumbsUpdate()
  const breadcrumbItems = useBreadcrumbs()

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  /********************************************************
                        FUNCTIONS
   ********************************************************/
  // MODAL
  const openAddModal = () => {
    setIsAddModalOpen(true)
  }

  const closeModal = () => setIsAddModalOpen(false)

  const handleAddCancel = () => {
    closeModal()
  }

  // ----------------------------------------------------------------------
  // TABLE ON CHANGE
  // ----------------------------------------------------------------------
  const onTableChange = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra)
    setPagination(pagination)
    onUpdate(pagination.current)
  }

  // ----------------------------------------------------------------------
  // DATE PICKER AND FILTERS
  // ----------------------------------------------------------------------
  const getDaysArray = (start, end) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt))
    }
    return arr
  }

  const getColumnDateFilterProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <>
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm' }}
          format='YYYY-MM-DD HH:mm'
          onChange={(value) => {
            clearFilters()
            let dates = getDaysArray(value[0]['$d'], value[1]['$d'])
            console.log(dates)
            setSelectedKeys(dates)
          }}
          onOk={(value) => {
            console.log('onOk: ', value)
            let dates = getDaysArray(value[0]['$d'], value[1]['$d'])
            console.log(dates)
            setSelectedKeys(dates)
          }}
        />
        <Button
          type='primary'
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size='small'
          style={{
            width: 90,
          }}
        />
      </>
    ),
    filterIcon: (filtered) => <CalendarOutlined />,
    onFilter: (value, record) => {
      let dateString = `${value.getFullYear()}-${value.getMonth()}-${value.getDay()}`
      return record.lastLock.includes(dateString)
    },
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  // ----------------------------------------------------------------------
  // ACTIONS AND COLUMNS
  // ----------------------------------------------------------------------
  const drawActions = (data) => {
    let tempActions = []
    if (tableActions.includes('content'))
      tempActions.push(
        <ContentButton
          inputAttributes={elementInputAttributes}
          element={data}
          entity={props.entity}
          openModal={openAddModal}
        />
      )
    if (tableActions.includes('edit'))
      tempActions.push(
        <EditButton
          inputAttributes={elementInputAttributes}
          element={data}
          entity={props.entity}
          onUpdate={() => onUpdate(pagination.current)}
        />
      )
    if (tableActions.includes('mqttlog')) tempActions.push(<MqttLogButton element={data} />) // sends the message data as an element
    if (tableActions.includes('calendar')) tempActions.push(<CalendarButton element={data} />)
    if (tableActions.includes('lock')) tempActions.push(<LockButton element={data} />)
    if (tableActions.includes('history')) tempActions.push(<HistoryListButton element={data} />)
    if (tableActions.includes('remove'))
      tempActions.push(<RemoveButton element={data} route={props.route} onDelete={() => onDelete(1)} />)

    return <Space>{tempActions}</Space>
  }

  const getPropertyValue = (item, property) => {
    let value = item

    if (property.includes('.')) {
      const properties = property.split('.')
      for (const property of properties) {
        value = value[property]
      }
    } else {
      value = item[property]
    }
    return value
  }

  const getColumns = () => {
    let temp = []

    tableCols.forEach((column) => {
      if (column.key === 'urlIcon') {
        for (let i = 0; i < tableData.length; i++) {
          tableData[i].urlIcon = (
            <Tooltip title={tableData[i].url}>
              <a href={tableData[i].url} target='_blank' rel='noreferrer'>
                <LinkOutlined style={{ fontSize: 'large', color: token.colorPrimary }} />
              </a>
            </Tooltip>
          )
        }
      }
      if (column.key === 'serversIcon') {
        for (let i = 0; i < tableData.length; i++) {
          tableData[i].serversIcon = (
            <Tooltip title={t('Servers')}>
              <ServerButton element={tableData[i].servers} />
            </Tooltip>
          )
        }
      }
      if (column.key === 'statusIcon') {
        for (let i = 0; i < tableData.length; i++) {
          if (tableData[i].status === 'red')
            tableData[i].statusIcon = <ExclamationCircleFilled style={{ fontSize: 'large', color: token.colorError }} />
          if (tableData[i].status === 'green')
            tableData[i].statusIcon = <CheckCircleFilled style={{ fontSize: 'large', color: token.colorSuccess }} />
          if (tableData[i].status === 'yellow')
            tableData[i].statusIcon = <ClockCircleFilled style={{ fontSize: 'large', color: token.colorWarning }} />
        }
      }

      /**
       * Create FILTERS for columns
       */
      let responsive = ['sm']
      let sorter = (a, b) => a.from.length - b.from.length
      let sortDirections = ['descend', 'ascend']
      let filters = []
      let elementsFiltered = []
      for (let i = 0; i < tableData.length; i++) {
        const value = getPropertyValue(tableData[i], column.key)

        if (!elementsFiltered.includes(tableData[i][column.key])) {
          filters.push({
            text: value,
            value: value,
          })
        }
        elementsFiltered.push(value)
      }
      // normal column with name filter
      if(column.as === 'img'){
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          filters: filters,
          render: (_, item) => getPropertyValue(item, column.key) && <img src={`${ENV.BACKEND_URL}${getPropertyValue(item, column.key)}`} alt='Una imagen' width={100} />
        })
      }
      else if (column.filter === 'name') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          filters: filters,
          onFilter: (value, record) => record[column.key].indexOf(value) === 0,
          render: (_, item) => getPropertyValue(item, column.key),
        })
      }
      // normal column with number filter
      else if (column.filter === 'number') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          sorter: (a, b) => a[column.key] - b[column.key],
          render: (_, item) => getPropertyValue(item, column.key),
        })
      }
      // normal column with text filter
      else if (column.filter === 'text') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          // responsive,
          ...getColumnSearchProps(column.key),
          render: (_, item) => getPropertyValue(item, column.key),
        })
      }
      // normal column with date filter
      else if (column.filter === 'date') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          // responsive,
          ...getColumnDateFilterProps(),
          render: (_, item) => getPropertyValue(item, column.key),
        })
      }
      // normal column with boolean filter
      else if (column.filter === 'boolean') {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          sorter: (a, b) => a[column.key] - b[column.key],
          render: (_, item) => getPropertyValue(item, column.key),
        })
      }
      // normal column without filter
      else if (column.filter === 'none' || !column.filter) {
        temp.push({
          title: t(column.name),
          dataIndex: column.key,
          key: column.key,
          // responsive,
          render: (_, item) => getPropertyValue(item, column.key),
        })
      }
    })

    if (tableActions.length > 0) {
      temp.push({
        title: <SettingOutlined style={{ fontSize: 'large' }} />,
        dataIndex: 'actions',
      })

      for (let i = 0; i < tableData.length; i++) {
        tableData[i].actions = drawActions(tableData[i])
      }
    }

    return temp
  }
  const tableColumns = getColumns()

  // ----------------------------------------------------------------------
  // EXPORT TO CSV
  // ----------------------------------------------------------------------
  const prepareExport = (cols, data) => {
    const csvData = []
    if (data.length > 0) {
      let titleRow = []
      for (let i = 0; i < cols.length; i++) {
        if (cols[i].dataIndex !== 'actions') {
          titleRow.push(cols[i].title)
        }
      }
      csvData.push(titleRow)
      for (let i = 0; i < data.length; i++) {
        let row = []
        for (let x = 0; x < cols.length; x++) {
          if (cols[x].dataIndex !== 'actions') {
            row.push(data[i][cols[x].dataIndex])
          }
        }
        csvData.push(row)
      }
    }
    return csvData
  }
  const csvData = prepareExport(tableColumns, tableData)

  useEffect(() => {
    console.log('Updated breadcrumbs.')
    updateBreadcrumbs(location)
  }, [location, updateBreadcrumbs])

  const renderForm = (entity) => {
    let form
    switch (entity) {
      case 'user': {
        form = (
          <UserForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd && onAdd(pagination.current)
              closeModal()
            }}
          />
        )
        break
      }
      case 'categoriesAwards': {
        form = (
          <CategoriesAwardsForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd && onAdd(pagination.current)
              closeModal()
            }}
          />
        )
        break
      }
      case 'awards': {
        form = (
          <AwardsForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd && onAdd(pagination.current)
              closeModal()
            }}
          />
        )
        break
      }
      case 'categoriesAssociations': {
        form = (
          <CategoriesAssociationsForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd && onAdd(pagination.current)
              closeModal()
            }}
          />
        )
        break
      }
      case 'associations': {
        form = (
          <AssociationForm
            isModalOpen={isAddModalOpen}
            onAdd={() => {
              onAdd && onAdd(pagination.current)
              closeModal()
            }}
          />
        )
        break
      }
      default: {
        form = <UpdateItemForm inputAttributes={elementInputAttributes} />
        break
      }
    }

    return form
  }

  return (
    <>
      <Row align='middle' justify='space-between'>
        <Col>
          <Breadcrumb>{breadcrumbItems}</Breadcrumb>
          <Title style={{ color: token.colorText }}>{t(tableTitle)}</Title>
        </Col>
        <Col>
          <Space>
            {/* <CSVLink
              filename={"Table.csv"}
              data={csvData}
              className="btn btn-primary"
            >
              {t('Export to CSV')}
            </CSVLink> */}
            {/* <Button>{t('Back')}</Button> */}
            <Tooltip title={t('New') + ' ' + t(tableTitle)}>
              <Button
                type='primary'
                shape='circle'
                className='buttonCentered'
                icon={<PlusOutlined />}
                onClick={openAddModal}
              />
            </Tooltip>
            <Modal width={800} footer={null} title={t('Add Element')} open={isAddModalOpen} onCancel={handleAddCancel}>
              {renderForm(props.entity)}
            </Modal>
          </Space>
        </Col>
      </Row>
      <Table
        dataSource={tableData}
        columns={tableColumns}
        rowSelection={
          tableHasRowSelection
            ? {
                type: selectionType,
                ...rowSelection,
              }
            : false
        }
        pagination={pagination}
        onChange={onTableChange}
      />
    </>
  )
}

export default CommonTable
