import { Button, Col, Form, Input, Typography, Row } from 'antd';
import React, { useState } from 'react';
import { Formik, ErrorMessage  } from 'formik'
import * as Yup from 'yup';


const { Text } = Typography

const UpdateItemForm = ({ inputAttributes, element }) => {

    // --------------------------------------------
    // Alert created timer
    const [isAlertVisible, setIsAlertVisible] = useState(false);

    // This function won't be necessary anymore
    // const onFinish = (values) => {
    //     console.log('Success:', values);
    //     // TODO: update with api

    //     // Show Created text
    //     setIsAlertVisible(true)
    //     setTimeout(() => {
    //         setIsAlertVisible(false);
    //     }, 2000);
    // };
    // --------------------------------------------

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const validate = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
      
        if (!values.surname) {
            errors.surname = 'Required';
        } 

        if (!values.country) {
            errors.country = 'Required';
        }

        if (!values.city) {
            errors.city = 'Required';
        }

        if (!values.user) {
            errors.user = 'Required';
        }

        if (!values.role) {
            errors.role = 'Required';
        } 
      
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        
        console.log(errors);
        return errors;
      };

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        surname: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
      });

    return (
        <Formik 
            initialValues={
                {...element}
            }
            validate={validate}
            onSubmit={(values, actions) => {
                console.log(values);
                // Add logic to call the API to update values

                setTimeout(() => {
                    alert(JSON.stringify(values));
                    actions.setSubmitting(false);
                }, 1000);
            }}
        >
            {formik => (
                <Form
                    labelAlign='top'
                    // labelCol={{
                    //     span: 8,
                    // }}
                    // wrapperCol={{
                    //     span: 16,
                    // }}
                    style={{
                        maxWidth: 900,
                    }}
                    onFinish={formik.handleSubmit}
                    onFinishFailed={onFinishFailed}
                >
                    {element &&
                        <h4>ID: {element.id}</h4>
                    }
                    <Row gutter={24}>
                    {inputAttributes.map((attr, index) => (
                        <Col span={12} key={index} >
                            <Form.Item
                                key={index}
                                label={attr.name}
                                name={attr.key}
                                className={{'input-error': formik.errors.hasOwnProperty(attr.key)}}
                                // rules={[
                                //     {
                                //         required: attr.required
                                //     },
                                // ]}
                            >
                                {element
                                    ?
                                    <>
                                        <Input
                                            name={attr.key} 
                                            id={attr.key} 
                                            placeholder={element[attr.key]} 
                                            onChange={formik.handleChange}
                                            defaultValue={element[attr.key]}
                                        />
                                        {/* <Text copyable>{element[attr.key]}</Text> */}
                                        <ErrorMessage name={attr.key} />
                                    </>
                                    :
                                    <Input copyable />
                                }
                            </Form.Item>
                        </Col>
                    ))}
                    </Row>
                    
                    <Col span={24} >
                        <Form.Item
                            wrapperCol = {{ xs: { span: 24, offset: 0 }, sm: { span: 12, offset: 6 }}}
                        >
                            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Col>
                    {isAlertVisible &&
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                        >
                            <Text type="success">Element edited!</Text>
                        </Form.Item>
                    }
                </Form>
            )}
        </Formik>
    )
};

export default UpdateItemForm;