/**
 * Nombre fichero: MapMenu.js
 * Autor: Yeray Candel Sampedro
 * Fecha: 14/07/2022
 *
 * Descripcion: Componente menu lateral izquierdo
 *
 * Fecha de moficiacion: 14/10/2023
 * Modificador: Daniel Burruchaga
 */
import React, { useState, useEffect } from 'react'
// Estilos
import styles from './MapMenu.module.css'
import MenuItem from './MenuItem/MenuItem.component'
import { BasicApi } from '../../../API/basicApi'
import { ENV } from '../../../utils'
import { useSearchContext } from '../../../Hooks/useSearchContext'

const MapMenu = () => {
  const [associationsCategories, setAssociationsCategories] = useState([])
  const [categoriesSelected, setCategoriesSelected] = useState([])
  const searchContext = useSearchContext()

  const getAssociationsCategories = async () => {
    const apiAssociationsCategories = new BasicApi(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_ASSOCIATIONS}`)
    const associations_categories = await apiAssociationsCategories.getAll()
    setAssociationsCategories(associations_categories.result)
  }

  useEffect(() => {
    getAssociationsCategories()
  }, [])

  return (
    <div id='MapMenu'>
      <div className={styles.mainContainermapmenu}>
        <header>
          <h5>Filtro por categoría</h5>
        </header>
        {associationsCategories.map((category, i) => {
          return (
            <MenuItem
              key={i}
              category={category}
              title={category.name}
              setActivated={(category) => {
                if (categoriesSelected.includes(category)) {
                  searchContext.setCategoryFilter(categoriesSelected.filter((cat) => cat.id !== category.id))
                  setCategoriesSelected(categoriesSelected.filter((cat) => cat.id !== category.id))
                  return
                }
                searchContext.setCategoryFilter([...categoriesSelected, category])
                setCategoriesSelected([...categoriesSelected, category])
              }}
              linkActivated={categoriesSelected.includes(category)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MapMenu
