import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Select, Typography } from 'antd'
import { BasicApi } from '../../API/basicApi'
import { ueAuth } from '../../Hooks'

const { Text } = Typography

function Selector({ element, dataName, title, route, setValue }) {
  const { t } = useTranslation()
  const api = new BasicApi(route)
  const [data, setData] = useState([])

  const onValueChange = (value) => {
    console.log(dataName + ': ' + value)
    setValue(value)
  }

  /**
   * Creates options for all select element with the required input and then creates the Form
   */
  const generateFormOptions = () => {
    // Get all data for selector
    api.getAll().then((values) => {
        // values.push({
      //     name: t("None"),
      //     id: ""
      // })
      setData(values.result)
      console.log(dataName + ' loaded 👌')
    })
  }

  useEffect(() => {
    generateFormOptions()
  }, [])

  return (
    <Form.Item
      key={dataName}
      label={t(title)}
      name={dataName}
      rules={[
        {
          required: true,
        },
      ]}
    >
      <>
        <Select
          // mode="multiple"
          showSearch
          style={{
            width: 200,
          }}
          placeholder={t('Search to Select')}
          optionFilterProp='children'
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={data.map((values) => ({
            label: values.name,
            value: values.id,
          }))}
          onChange={onValueChange}
          defaultValue={element[dataName]}
        />
        <Text>{element[dataName]}</Text>
      </>
    </Form.Item>
  )
}

export default Selector
