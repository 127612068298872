import React, { useState } from "react";

import { theme } from "antd";
import { LinkOutlined } from '@ant-design/icons'
import Modal from "antd/es/modal/Modal";

import UpdateItemForm from "../../UpdateItemForm";
import { useTranslation } from "react-i18next";
import UserForm from "../../../pages/Users/components/userForm.component";


const { useToken } = theme;
/**
 * 
 * @param {*} param0 
 * @returns html of a column element
 */
const ContentButton = ({ inputAttributes, element, entity}) => {

    const { token } = useToken();
    const { t } = useTranslation();

    // Edit an element modal view
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    function openEditModal() {
        setIsEditModalOpen(true);
    };

    function handleEditCancel() {
        setIsEditModalOpen(false);
    };
    // -------------------------------

    function renderForm(entity) {
        let form
        switch(entity) {
            case 'user': {
                form = <UserForm element={element} isModalOpen={isEditModalOpen} />
                break;
            }

            default: {
                form = <UpdateItemForm inputAttributes={inputAttributes} element={element} />
                break;
            }
        }

        return form
    }

    return (
        <>
            <LinkOutlined style={{ "fontSize": "large", "color": token.colorPrimary }} onClick={openEditModal} />
            <Modal width={900} footer={null} title={t(`Edit ${entity ? entity : 'element'}`)} open={isEditModalOpen} onCancel={handleEditCancel}>
                {renderForm(entity)} 
            </Modal>
        </>
    )

};

export default ContentButton;
