export const ENV = {
    BASE_API: process.env.REACT_APP_BASE_API,
    BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    API_ROUTES:{
        REGISTER: "signup",
        LOGIN: "auth/signin",
        MEASURE: "measure",
        USER: "user",
        ASSOCIATIONS: "associations",
        AWARDS: "awards",
        CATEGORIES_AWARDS: "categories/awards",
        CATEGORIES_ASSOCIATIONS: "categories/associations",
        // REFRESH_TOKEN: "auth/refresh",
    },
    JWT:{
        ACCESS:"token",
        REFRESH:"refreshToken",
    }
}

export const PAGINATION_SIZE = 10