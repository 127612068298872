import { useEffect, useState } from 'react'
import CommonTable from '../../components/CommonTable'
import awardService from '../../services/award.service'
import { awards_props, columns } from './awards.props'
import { PAGINATION_SIZE } from '../../utils'

export default function Awards() {
  const [tableProps, setTableProps] = useState(awards_props)

  const getAwards = async (page = 1) => {
    try {
      const awards = await awardService.getAllAwards(PAGINATION_SIZE, page * PAGINATION_SIZE - PAGINATION_SIZE)
      setTableProps((prevState) => ({
        ...prevState,
        data: awards.result,
        total: awards.count
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'surname')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'surname' || column.key === 'email')
  }

  useEffect(() => {
    getAwards()
    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns,
      }))
    }
  }

  return (
    <div className='content'>
      <CommonTable props={{ ...tableProps, onAdd: getAwards, onUpdate: getAwards, onDelete: getAwards }}></CommonTable>
    </div>
  )
}
