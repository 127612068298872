import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './styles/App.css'
import './i18n';

import { AuthProvider } from "./context/index";
// Components
import { Footer } from './components/footer.component'
import { CookiePolicy } from './components/CookiesPolicy'
import DashboardRouter from './Router/DashboardRouter/DashboardRouter.component';
import { SearchProvider } from './context/SearchContext/Index';

function App() {
  return (
    <AuthProvider>
      <SearchProvider>
        <div className="App">
          <DashboardRouter />
          <CookiePolicy />
          <Footer />
        </div>
      </SearchProvider>
    </AuthProvider>
  )
}

export default App
