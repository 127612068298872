import React from 'react';
import { useState } from 'react';
import { useTheme, useAuth } from '../../Hooks';
import { height } from '@mui/system';
import { Card, Col, Divider, Row, Select, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text, Title, Paragraph, Link } = Typography

const MyProfile = () => {
  const { user, isAuthenticated, logout } = useAuth();
  const [profileImage, setProfileImage] = useState(`https://robohash.org/${Math.floor(Math.random() * 100)}.png?size=150x150`);
  console.log("MyProfile")
  console.log(user)

  const { t } = useTranslation();

  // const councils = [
  //   {
  //     value: "valencia",
  //     label: "Valencia"
  //   },
  //   {
  //     value: "paterna",
  //     label: "Paterna"
  //   }
  // ]

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col style={{ padding: 8 }} xl={12} span={24}>
          <Card>
            <Title>{user.name}</Title>
            <Link className="nav-link" onClick={logout}>
              Logout
            </Link>
            <Paragraph>
              <Text strong>{t('Name')}: </Text><Text>{user.name ? user.name : ""}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>{t('Last Name')}: </Text><Text>{user.surname ? user.surname : ""}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>{t('Email address')}: </Text><Text>{user.email ? user.email : ""}</Text>
            </Paragraph>
            <Paragraph>
              <Text strong>{t('Username')}: </Text><Text>{user.email ? user.email : ""}</Text>
            </Paragraph>
            <Divider dashed />

            <Text strong>{t('Address')}: </Text>
            <Text>
              <Tooltip title={t('Country')}>{user.country ? user.country : ""}</Tooltip> /
              <Tooltip title={t('State')}>{user.state ? user.state : ""}</Tooltip> /
              <Tooltip title={t('City')}>{user.locality ? user.locality : ""}</Tooltip> /
              <Tooltip title={t('Street')}>{user.street ? user.street : ""}</Tooltip>
            </Text>
            <Paragraph>
              <Text strong>{t('Postal Code')}: </Text><Text>{user.postalCode ? user.postalCode : ""}</Text>
            </Paragraph>

            <Divider dashed />

            <Paragraph>
              <Text strong>{t('Phone')}: </Text><Text>{user.phone ? user.phone : ""}</Text>
            </Paragraph>
          </Card>
        </Col>

        {/* <Col style={{ padding: 8 }} xl={12} span={24}>
          <Card>
            <Title level={2}>{t('Council')}</Title>
            <Select
              mode='multiple'
              style={{
                width: "100%",
              }}
              options={councils}
            />
          </Card>
        </Col> */}
      </Row>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col style={{ padding: 8 }} xl={12} span={24}>
          <Card>
            <Title level={2}>{t('Business')}</Title>
            <Select
              mode='multiple'
              style={{
                width: "100%",
              }}
              options={councils}
            />
          </Card>
        </Col>
      </Row> */}
    </>
  );
};

export default MyProfile;
