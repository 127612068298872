import http from '../API/http-common'
import { ENV } from '../utils'

class AssociationService {
  async getCategoriesAssociations(limit, offset) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_ASSOCIATIONS}`, {
      params: { limit: limit, offset: offset },
    })
    return data
  }

  async addCategoriesAssociation(values) {
    const { data } = await http.post(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_ASSOCIATIONS}`, values, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  async updateCategoryAssociation(id, values) {
    const { data } = await http.put(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_ASSOCIATIONS}/${id}`, values, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  async getAssociations(limit, offset) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.ASSOCIATIONS}`, {
      params: { limit: limit, offset: offset },
    })
    return data
  }

  async getAssociationsAwards(id) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.ASSOCIATIONS}/${id}/${ENV.API_ROUTES.AWARDS}`)
    return data
  }

  async addAssociation(values) {
    const { data } = await http.post(`${ENV.BASE_API}/${ENV.API_ROUTES.ASSOCIATIONS}`, values, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  async updateAssociation(id, values) {
    const { data } = await http.put(`${ENV.BASE_API}/${ENV.API_ROUTES.ASSOCIATIONS}/${id}`, values, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }
}

export default new AssociationService()
