import http from '../API/http-common'
import { ENV } from '../utils'

class UserService {
  async getAllUsers(limit, offset) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.USER}`, { params: { limit: limit, offset: offset } })
    return data
  }
  async getUserById(id) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.USER}/${id}`)
    return data
  }
  async addUser(_data) {
    console.log("addUser",_data)
    const { data } = await http.post(`${ENV.BASE_API}/${ENV.API_ROUTES.USER}/`, _data)
    return data
  }
  async editUserById(id, _data) {
    const { data } = await http.put(`${ENV.BASE_API}/${ENV.API_ROUTES.USER}/${id}`, _data)
    return data
  }
  async deleteUserById(id) {
    const { data } = await http.delete(`${ENV.BASE_API}/${ENV.API_ROUTES.USER}/${id}`)
    return data
  }
}
export default new UserService()
