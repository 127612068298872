import '../../styles/App.css'
import '../../styles/Map.css'
import 'leaflet/dist/leaflet.css'
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet'
import L from 'leaflet'
import React, { useEffect, useState } from 'react'
import oCityMarkerA from '../../assets/markerA.png'
import MarkerClusterGroup from 'react-leaflet-cluster'
import MapMenu from '../Map/MapMenu/MapMenu.component'
import WindowMarker from './WindowMaker/WindowMarker.component'
import { List } from 'react-virtualized'
import PlayContents from './PlayContents/PlayContents.component'
import { v4 as uuidv4 } from 'uuid'
import { BasicApi } from '../../API/basicApi'

import LazyLoad from 'react-lazyload'
import { ENV } from '../../utils'
import { useSearchContext } from '../../Hooks/useSearchContext'

const windowMarkerRef = React.createRef()
const playContentsRef = React.createRef()
const mapRef = React.createRef()
const zoomRef = React.createRef()
const { forwardRef, useImperativeHandle } = React

const ZOOMAP = forwardRef((props, ref) => {
  const [zoomLevel, setZoomLevel] = useState(7) // initial zoom level provided for MapContainer

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom())
    },
  })

  // Funcion de react Native para hacer llamadas de funciones hijas desde el padre
  useImperativeHandle(ref, () => ({
    getZoomLevel: zoomLevel,
  }))
  props.setZoom(zoomLevel)

  return null
})

export function Map() {
  const [zoom, setZoom] = useState(0)
  const [associations, setAssociations] = useState([])
  const [filteredAssociations, setFilteredAssociations] = useState([])
  const [awards, setAwards] = useState([])
  const { search, categoryFilter } = useSearchContext()

  async function getAssociationsFromApi(page) {
    const pageSize = 200
    const apiAssociations = new BasicApi(
      `${ENV.BASE_API}/${ENV.API_ROUTES.ASSOCIATIONS}?limit=${pageSize}&offset=${(page - 1) * pageSize}`
    )
    const response = await apiAssociations.getAll()
    return response
  }

  async function fetchAllAssociations() {
    let page = 1
    let totalAssociationsNumber = 0
    let _count = 0
    let associations = []
    do {
      const assocaitionsData = await getAssociationsFromApi(page)
      const { result, count } = assocaitionsData
      _count = count
      if (result.length > 0) {
        associations = associations.concat(result)
        totalAssociationsNumber += result.length
      }
      page++
    } while (totalAssociationsNumber < _count)
    console.log('associations: ', associations)
    return associations
  }

  const getMapData = async () => {
    const apiAwards = new BasicApi(`${ENV.BASE_API}/${ENV.API_ROUTES.AWARDS}`)

    const _associations = await fetchAllAssociations()
    _associations.map((item) => item.socialNetwork = JSON.parse(item.socialNetwork))

    setAssociations(_associations)

    const _awards = await apiAwards.getAll()
    setAwards(_awards.result)

  }

  useEffect(() => {
    getMapData()
  }, [])

  useEffect(() => {
    let filtered = associations

    if (search) {
      filtered = associations.filter((association) => association.name.toLowerCase().includes(search.toLowerCase()))
    }

    if (categoryFilter && categoryFilter.length > 0) {
      filtered = associations.filter((association) =>
        categoryFilter.some((cat) => cat.id === association.category_association_id)
      )
    }

    setFilteredAssociations(filtered)
  }, [search, associations, categoryFilter])

  const createIcon = (url) =>
    L.icon({
      iconUrl: url,
      iconRetinaUrl: url,
      iconAnchor: [30, 30],
      popupAnchor: [0, -30],
      iconSize: [60, 60],
    })

  const handleClickMarker = (item) => {
    windowMarkerRef.current.setData(item)
    if (!windowMarkerRef.current.visible()) {
      windowMarkerRef.current.setVisible(true)
    }
    // if (zoom <= 10)
    if (item.location)
      mapRef.current.flyTo([parseFloat(item.location.split(',')[0]), parseFloat(item.location.split(',')[1])])
    if (item.location_manif)
      mapRef.current.flyTo(
        [parseFloat(item.location_manif.split(',')[0]), parseFloat(item.location_manif.split(',')[1])],
        zoom <= 13 ? zoom + 3 : zoom,
        {
          duration: 0.5,
        }
      )
  }

  function putAssociationsMarkersInMap() {
    return (
      <List
        width={100}
        height={100}
        rowCount={1}
        rowHeight={20}
        rowRenderer={() =>
          filteredAssociations.map((item) =>
            item ? (
              <Marker
                key={uuidv4()}
                position={[item.latitude, item.longitude]}
                icon={createIcon(`${ENV.BACKEND_URL}${item.category_association.icon}`)}
                eventHandlers={{
                  click: () => handleClickMarker(item),
                }}
              >
                <Popup>{item.name}</Popup>
              </Marker>
            ) : null
          )
        }
      />
    )
  }

  function putAwardsMarkersInMap() {
    return (
      <List
        width={100}
        height={100}
        rowCount={1}
        rowHeight={20}
        rowRenderer={() =>
          awards.map((item) =>
            item.id ? (
              <Marker
                key={uuidv4()}
                position={[parseFloat(item.location.split(',')[0]), parseFloat(item.location.split(',')[1])]}
                icon={createIcon(oCityMarkerA)}
                eventHandlers={{
                  click: () => handleClickMarker(item),
                }}
              >
                <Popup>{item.city_name}</Popup>
              </Marker>
            ) : null
          )
        }
      />
    )
  }

  return (
    <div
      onWheel={(e) => {
        console.log('a')
      }}
    >
      {/* Ventana de los marcadores */}
      <WindowMarker ref={windowMarkerRef} playContentsRef={playContentsRef} />
      <PlayContents ref={playContentsRef} windowMarkerRef={windowMarkerRef} />
      <MapMenu />

      <MapContainer
        className='markercluster-map'
        center={[40.4165, -3.70256]}
        zoom={6}
        maxZoom={18}
        style={{ margin: '0px', height: '100vh', zIndex: 0 }}
        ref={mapRef}
        children={<TileLayer></TileLayer>}
      >
        <ZOOMAP ref={zoomRef} setZoom={setZoom} />

        <TileLayer
          url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/Ma
          pServer/tile/{z}/{y}/{x}'
          attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS,
          Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri
          (Thailand), TomTom, 2012'
          edgeBufferTiles={2}
        />
        {zoom < 7 && (
          <TileLayer
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}'
            attribution='Tiles &copy; Esri &mdash; Source: US National Park Service'
          />
        )}
        <MarkerClusterGroup chunkedLoading>
          <LazyLoad once={true} threshold={200}>
            {putAssociationsMarkersInMap()}
          </LazyLoad>
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  )
}
