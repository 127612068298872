import React from 'react'
import { Formik } from 'formik'
import { Typography, Form, Input, Select, Button, Row, Col } from 'antd'
import { Form as AntdForm } from 'antd'
import associationService from '../../../services/association.service'
import { ENV } from '../../../utils'

const { Item } = AntdForm
const { Option } = Select
const { Text } = Typography

const CategoriesAssociationsForm = ({ element, isModalOpen, onAdd, onUpdate }) => {
  return (
    <Formik
      initialValues={{
        name: element?.name ?? '',
        icon: null,
      }}
      onSubmit={async (values, { resetForm }) => {
        console.log('SUBMIT: ', values)
        try {
          const formData = new FormData()
          formData.append('name', values.name)
          values.icon && formData.append('icon', values.icon)

          if (element) {
            const response = await associationService.updateCategoryAssociation(element.id, formData)
            alert(
              response.message === 'succesfull update'
                ? 'Actualizado correctamente :)'
                : 'Error en el proceso de actualización'
            )
            onUpdate && onUpdate()
          } else {
            const response = await associationService.addCategoriesAssociation(formData)
            alert(response.id ? 'Creado correctamente :)' : 'Error en el proceso de creación')
            onAdd && onAdd()
            resetForm()
          }
        } catch (error) {
          alert('Error en el proceso de creación')
          console.log(error)
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form>
          <Row gutter={24}>
            <Col xs={24} md={6}>
              <Form.Item
                key={"name"}
                label={"Nombre"}
                name={"name"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  id='name'
                  name='name'
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  value={values.name}
                  placeholder='Name'
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                key={"icon"}
                label={"Icono en mapa"}
                name={"icon"}
                rules={[{ required: true, }]}>
                <Input id='icon'
                  type='file'
                  name='icon'
                  extension='.jpg,.jpeg,.png,.ico,.svg'
                  onChange={(e) => setFieldValue('icon', e.target.files[0])}
                />
              </Form.Item>
            </Col>
          </Row>
          {element?.icon && (
            <Row>
              <img src={`${ENV.BACKEND_URL}${element.icon}`} alt='Una imagen' width={100} />
            </Row>
          )}
          <Item>
            <Button type='primary' onClick={handleSubmit} style={{ margin: '10px' }}>
              Submit
            </Button>
          </Item>
        </Form>
      )}
    </Formik>
  )
}

export default CategoriesAssociationsForm
