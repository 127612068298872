
import { Routes, Route } from 'react-router-dom';
import Users from '../pages/Users/users.component';
import Associations from '../pages/Associations/associations.component'
import { Map } from '../components/Map/map.component';
import LoginPage from '../pages/Login/login.component';
import MiProfilePage from '../pages/MyProfile/myProfile.component';
import Awards from '../pages/Awards/awards.component'
import CategoriesAwards from '../pages/CategoriesAwards/categoriesAwards.component'
import CategoriesAssociations from '../pages/CategoriesAssociations/categoriesAssociations.component'
import { PrivateRoute } from './PrivateRoute.component';

function DashboardRoutes() {
    return (
        <Routes>
            <Route exact path="" element={<Map />} />
            <Route path="/dashboard/user" element={
                <PrivateRoute>
                    <Users />
                </PrivateRoute>
            } />
            <Route path="/dashboard/associations" element={
                <PrivateRoute>
                    <Associations />
                </PrivateRoute>
            } />
            <Route path="/dashboard/awards" element={
                <PrivateRoute>
                    <Awards />
                </PrivateRoute>
            } />
            <Route path="/dashboard/categories/awards" element={
                <PrivateRoute>
                    <CategoriesAwards />
                </PrivateRoute>
            } />
            <Route path="/dashboard/categories/associations" element={
                <PrivateRoute>
                    <CategoriesAssociations />
                </PrivateRoute>
            } />
            <Route path="/sign-in" element={<LoginPage />} />
            <Route path="/admin" element={
                <PrivateRoute>
                    <MiProfilePage />
                </PrivateRoute>
            } />
        </Routes>
    );
}

export default DashboardRoutes;