export class Awards {
  constructor(element) {
    this.id = element?.id ?? 0
    this.name = element?.name ?? ''
    this.deliveryDate = element?.deliveryDate ?? ''
    this.creativeIdentity = element?.creativeIdentity ?? ''
    this.association_id = element?.association_id ?? 0
    this.category_id = element?.category_id ?? ''
    this.image = null
  }
}
