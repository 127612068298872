import React, { useState } from "react";

import { theme, Tooltip, Table } from "antd";
import { ClusterOutlined } from '@ant-design/icons'
import Modal from "antd/es/modal/Modal";
import {useTranslation} from 'react-i18next'

const { useToken } = theme;
/**
 * 
 * @param {*} param0 
 * @returns html of a column element
 */
const ServerButton = ({ element }) => {

    const { token } = useToken();
    const {t} = useTranslation();

    // Element modal view
    const [isModalOpen, setIsModalOpen] = useState(false);

    function openModal() {
        setIsModalOpen(true);
    };

    function handleCancel() {
        setIsModalOpen(false);
    };
    // -------------------------------

    const columns = [
        {
            title: "Name",
            dataIndex: "name"
        },
        {
            title: "Type",
            dataIndex: "type"
        },
        {
            title: "PK ID",
            dataIndex: "pkid"
        }
    ]

    // Fill data for table
    let tableData = []
    for (let i = 0; i < element.length; i++) {
        tableData.push({
            key: i,
            name: element[i].name,
            type: element[i].type,
            pkid: element[i].pkid
        })
    }

    return (
        <>
            <ClusterOutlined style={{ "fontSize": "large", "color": token.colorPrimary }} onClick={openModal} />
            <Modal footer={null} title={t("Servers")} open={isModalOpen} onCancel={handleCancel}>
                <Table columns={columns} dataSource={tableData}></Table>
            </Modal>
        </>
    )

};

export default ServerButton;
