import http from './http-common'

export class BasicApi {
  constructor(route) {
    this.route = route
  }

  async getAll() {
    const url = this.route
    try {
      const { data: result } = await http.get(url)

      if (result?.data?.db) return result.data.db

      return result
    } catch (error) {
      console.log('api error:', error)
      throw error.response.data
    }
  }

  async getOne(id) {
    const url = `${this.route}/${id}`
    try {
      const { data: result } = await http.get(url)
      return result
    } catch (error) {
      console.log('api error:', error)
      throw error.response.data
    }
  }

  async postOne(data) {
    const url = this.route
    console.log('POST DATA :', data)
    try {
      const { data: result } = await http.post(url, data)
      return result
    } catch (error) {
      console.log('api error:', error)
      throw error.response.data
    }
  }

  async putOne(data, id) {
    const url = `${this.route}/${id}`
    try {
      const { data: result } = await http.put(url, data)
      return result
    } catch (error) {
      console.log('api error:', error)
      throw error.response.data
    }
  }
  async deleteOne(id) {
    const url = `${this.route}/${id}`
    try {
      const { data: result } = await http.delete(url)
      return result
    } catch (error) {
      console.log('api error:', error)
      throw error.response.data
    }
  }
}
