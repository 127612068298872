import React from 'react';
import { CDBModalFooter, CDBFooterLink, CDBBtn,   CDBBox } from 'cdbreact';

export const Footer = () => {
  return (
    <CDBModalFooter style={{backgroundColor: '#C6CED1', zIndex: 1, position: 'fixed', bottom: 0, left: 0, width: '100%'}} className="shadow">
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="mx-auto flex-wrap"
          style={{ width: '80%' , height: "60px" }}
      >
        {/* <CDBBox display="flex" alignItems="center">
          <a href="/" className="d-flex align-items-center p-0 t">
            <img
              alt="logo"
              src="https://ocitytest.webs.upv.es/assets/dist/img/logo-ocity-black.png"
              width="100"
            />
          </a>
          
        </CDBBox> */}
        <CDBBox>
        <a href="" className="d-flex align-items-center p-0 ">Mail</a>
        </CDBBox>
        <CDBBox>
        <a href="/" className="d-flex align-items-center p-0 " >Home</a>
        </CDBBox>
        <CDBBox>
        <a href="../assets/privacy.pdf" download  className="d-flex align-items-center p-0 ">PrivacyPolicy</a>
        </CDBBox>
        <CDBBox>
          <small className="ml-2">&copy; 2023 copyright PROMIS. </small>
        </CDBBox>
        {/* <CDBBox display="flex">
        <a href="/" className="d-flex align-items-center p-0 t">
            <img
              alt="logo"
              src="https://ocitytest.webs.upv.es/assets/dist/img/eu_flag_co_funded.jpg"
              width="100px"
            />
          </a>
        </CDBBox> */}
      </CDBBox>
    </CDBModalFooter>
  );
};