import { ENV } from "../../utils"

export const columns = [
  {
    name: 'Nombre',
    key: 'name',
    filter: 'text',
  },
  {
    name: 'Fecha de entrega',
    key: 'deliveryDate',
    filter: 'text',
  },
  {
    name: 'Identidad Creadora',
    key: 'creativeIdentity',
    filter: 'text',
  },
  {
    name: 'Associación',
    key: 'association.name',
    filter: 'text',
  },
  {
    name: 'Categoría',
    key: 'category.name',
    filter: 'text',
  }, 
  {
    name: 'Imagen',
    key: 'image',
    as: 'img'
  },
]

export const awards_props = {
  route:`${ENV.BASE_API}/${ENV.API_ROUTES.AWARDS}`,
  title: 'Awards',
  entity: 'awards',
  columns: columns,
  actions: ['edit', 'remove'],
  inputAttributes: [
    {
      name: 'Nombre',
      key: 'name',
      required: true
    },
    {
      name: 'Fecha de entrega',
      key: 'deliveryDate',
      required: false,
    },
    {
      name: 'Identidad Creadora',
      key: 'creativeIdentity',
      required: false,
    },
    {
      name: 'Asociación',
      key: 'association_id',
      required: false,
    },
    {
      name: 'Categoría',
      key: 'category_id',
      required: false,
    },
  ],
  hasRowSelection: false,
  data: []
}