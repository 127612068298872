import { useEffect, useState } from 'react';
import { Login } from '../../components/Login/login.component';

export default function LoginPage() {
  // const columns = [
  //   {
  //     name: 'Name',
  //     key: 'name',
  //     filter: 'text',
  //   },
  //   {
  //     name: 'Surname',
  //     key: 'surname',
  //     filter: 'text',
  //   },
  //   {
  //     name: 'Email',
  //     key: 'email',
  //     filter: 'text',
  //   },
  //   {
  //     name: 'Country',
  //     key: 'country',
  //     filter: 'text',
  //   },
  //   {
  //     name: 'City',
  //     key: 'city',
  //     filter: 'text',
  //   },
  //   {
  //     name: 'Role',
  //     key: 'role',
  //     filter: 'text',
  //   },
  // ]
  // const [tableProps, setTableProps] = useState({
  //   title: 'Users',
  //   entity: 'user',
  //   columns: columns,
  //   actions: ['edit', 'remove'],
  //   inputAttributes: [
  //     {
  //       name: 'Name',
  //       key: 'name',
  //       required: true
  //     },
  //     {
  //       name: 'Surname',
  //       key: 'surname',
  //       required: false,
  //     },
  //     {
  //       name: 'Email',
  //       key: 'email',
  //       required: false,
  //     },
  //     {
  //       name: 'Country',
  //       key: 'country',
  //       required: false,
  //     },
  //     {
  //       name: 'City',
  //       key: 'city',
  //       required: false,
  //     },
  //     {
  //       name: 'Role',
  //       key: 'role',
  //       required: false,
  //     },
  //     {
  //       name: 'User',
  //       key: 'user',
  //       required: false,
  //     },
  //     {
  //       name: 'Api access',
  //       key: 'api_access',
  //       required: false,
  //     },
  //     {
  //       name: 'Subscriber city',
  //       key: 'subscriber_city',
  //       required: false,
  //     },
  //     {
  //       name: 'Gender',
  //       key: 'gender',
  //       required: false,
  //     },   
  //   ],
  //   hasRowSelection: false,
  //   data: []
  // })

  // const getUsers = () => {
  //   const users =  userService.getAllUsers()
  //   setTableProps(prevState => ({
  //     ...prevState,
  //     data: users
  //   }))
  // }  

  // const renderMobileTable = (columns) => {
  //   return columns.filter(
  //     column => column.key === "name" || column.key === "surname"
  //   );
  // };

  // const renderTabletTable = (columns) => {
  //   return columns.filter(
  //     column => column.key === "name" || column.key === "surname" || column.key === "email"
  //   );
  // };

  // useEffect(() => {
  //   getUsers();
    
  //   window.addEventListener("resize", function() {
  //     resize()
  //   })
  //   resize()
  // }, [])

  return (
    <div className='content'>
      <Login/>
      
    </div>
  )
}