import http from '../API/http-common'
import { ENV } from '../utils'

class AwardService {
  async getAllAwards(limit, offset) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.AWARDS}`, {
      params: { limit: limit, offset: offset },
    })
    return data
  }

  async addAward(values) {
    const { data } = await http.post(`${ENV.BASE_API}/${ENV.API_ROUTES.AWARDS}`, values, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  async updateAward(id, values) {
    const { data } = await http.put(`${ENV.BASE_API}/${ENV.API_ROUTES.AWARDS}/${id}`, values, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    return data
  }

  async getAwardsCategories(limit, offset) {
    const { data } = await http.get(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_AWARDS}`, {
      params: { limit: limit, offset: offset },
    })
    return data
  }

  async addCategory(values) {
    const { data } = await http.post(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_AWARDS}`, values)
    return data
  }

  async updateCategory(id, values) {
    const { data } = await http.put(`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_AWARDS}/${id}`, values)
    return data
  }
}

export default new AwardService()
