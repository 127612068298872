import { ENV } from "../../utils"

export const columns = [
    {
      name: 'Nombre',
      key: 'name',
      filter: 'text',
    },
    {
      name: 'Apellidos',
      key: 'surname',
      filter: 'text',
    },
    {
      name: 'Email',
      key: 'email',
      filter: 'text',
    },
    {
      name: 'Estado',
      key: 'country',
      filter: 'text',
    },
    {
      name: 'Ciudad',
      key: 'city',
      filter: 'text',
    },
    {
      name: 'Rol',
      key: 'role',
      filter: 'text',
    },
  ]

export const user_props = {
  route:`${ENV.BASE_API}/${ENV.API_ROUTES.USER}`,
    title: 'Users',
    entity: 'user',
    columns: columns,
    actions: ['edit', 'remove'],
    inputAttributes: [
      {
        name: 'Nombre',
        key: 'name',
        required: true
      },
      {
        name: 'Apellidos',
        key: 'surname',
        required: false,
      },
      {
        name: 'Email',
        key: 'email',
        required: false,
      },
      {
        name: 'Estado',
        key: 'country',
        required: false,
      },
      {
        name: 'Ciudad',
        key: 'city',
        required: false,
      },
      {
        name: 'Rol',
        key: 'role',
        required: false,
      },
      {
        name: 'Usuario',
        key: 'user',
        required: false,
      },
     
    ],
    hasRowSelection: false,
    data: []
  }