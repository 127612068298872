import { Button, Col, Form, Input, Typography, Row, Switch, Select, theme, Divider, Upload, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next';

const { Text } = Typography

const ModalSupportForm = (props) => {
  const { t } = useTranslation();

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const validate = values => {
    const errors = {};
    if (!values.country) {
      errors.country = 'Required';
    }
    if (!values.city_name) {
      errors.city_name = 'Required';
    }
    if (!values.state_region) {
      errors.state_region = 'Required';
    }
    if (!values.ambassador) {
      errors.city_name = 'Required';
    }

    return errors;
  };

  return (<>
    <Formik
      initialValues={
        {/* ...ambassador */ }
      }
      validate={validate}
      onSubmit={(values, actions) => {
        console.log(values);
        setIsAlertVisible(true)
        // Add logic to call the API to update values

        setTimeout(() => {
          alert(JSON.stringify(values));
          actions.setSubmitting(false);
        }, 1000);
      }}
    >
      {formik => (

        <Form
          style={{
            maxWidth: 900,
          }}
          onFinish={formik.handleSubmit}
          onFinishFailed={onFinishFailed}
        >
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Form.Item
                label={t('Subject')}
                name='subject'
                className={{ 'input-error': formik.errors.country && formik.touched.country }}
              >
                <>
                  <Input
                    name='subject'
                    id='subject'
                    onChange={formik.handleChange}
                    defaultValue={""}
                  />
                  <ErrorMessage name='subject' />
                </>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label={t('Email')}
                name='email'
                className={{ 'input-error': formik.errors.city_name && formik.touched.city_name }}
              >
                <>
                  <Input
                    name='email'
                    id='email'
                    onChange={formik.handleChange}
                    defaultValue={""}
                  />
                  <ErrorMessage name='email' />
                </>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={t('Description')}
            name='description'
            className={{ 'input-error': formik.errors.INPUT_GRANDE && formik.touched.INPUT_GRANDE }}
          >
            <>
              <Input.TextArea
                name='description'
                id='description'
                onChange={formik.handleChange}
                defaultValue={""}
                maxLength={255}
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
              <ErrorMessage name='description' />
            </>
          </Form.Item>

          {isAlertVisible &&
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 16,
              }}
            >
              <Text type="success">Support message sended! Thanks</Text>
            </Form.Item>
          }
        </Form>
      )}
    </Formik></>
  )
};

export default ModalSupportForm;
