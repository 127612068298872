import { useEffect, useState } from 'react'
import CommonTable from '../../components/CommonTable'
import { categories_associations_props, columns } from './categoriesAssociations.props'
import associationService from '../../services/association.service'
import { PAGINATION_SIZE } from '../../utils'

export default function Associations() {
  const [tableProps, setTableProps] = useState(categories_associations_props)

  const getCategoriesAssociations = async (page = 1) => {
    try {
      const categories_associations = await associationService.getCategoriesAssociations(PAGINATION_SIZE, page * PAGINATION_SIZE - PAGINATION_SIZE)
      setTableProps((prevState) => ({
        ...prevState,
        data: categories_associations.result,
        total: categories_associations.count
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'surname')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name' || column.key === 'surname' || column.key === 'email')
  }

  useEffect(() => {
    getCategoriesAssociations()
    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns,
      }))
    }
  }

  return (
    <div className='content'>
      <CommonTable
        props={{
          ...tableProps,
          onUpdate: getCategoriesAssociations,
          onDelete: getCategoriesAssociations,
          onAdd: getCategoriesAssociations,
        }}
      ></CommonTable>
    </div>
  )
}
