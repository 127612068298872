// Estilos
import styles from './LeftMenu.module.css'
import $ from 'jquery';

const LeftMenuHooks = () => {

    const hide = () => {
        $('#backdrop').removeClass(styles.showMenu)
        $('#leftmenu').children().eq(0).removeClass(styles.mainContainerActivated)
        $('#leftmenu').children().eq(0).addClass(styles.mainContainerDesactivated)
    }
    const toggleMenu = () => {
        $('#backdrop').addClass(styles.showMenu)
        $('#leftmenu').children().eq(0).addClass(styles.mainContainerActivated)
        $('#leftmenu').children().eq(0).removeClass(styles.mainContainerDesactivated)
    }

    return { hide, toggleMenu };
}

export default LeftMenuHooks;
