import { useEffect, useState } from 'react'
import CommonTable from '../../components/CommonTable'
import { categories_awards_props, columns } from './categoriesAwards.props'
import awardService from '../../services/award.service'
import { PAGINATION_SIZE } from '../../utils'

export default function CategoriesAwards() {
  const [tableProps, setTableProps] = useState(categories_awards_props)

  const getCategoriesAwards = async (page = 1) => {
    try {
      const categories_awards = await awardService.getAwardsCategories(PAGINATION_SIZE, page * PAGINATION_SIZE - PAGINATION_SIZE)
      setTableProps((prevState) => ({
        ...prevState,
        data: categories_awards.result,
        total: categories_awards.count
      }))
    } catch (error) {
      console.log(error)
    }
  }

  const renderMobileTable = (columns) => {
    return columns.filter((column) => column.key === 'name')
  }

  const renderTabletTable = (columns) => {
    return columns.filter((column) => column.key === 'name')
  }

  useEffect(() => {
    getCategoriesAwards()
    window.addEventListener('resize', function () {
      resize()
    })
    resize()
  }, [])

  const resize = () => {
    if (window.innerWidth <= 650) {
      const cols = renderMobileTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else if (window.innerWidth > 650 && window.innerWidth < 820) {
      const cols = renderTabletTable(tableProps.columns)
      setTableProps((prevState) => ({
        ...prevState,
        columns: cols,
      }))
    } else {
      setTableProps((prevState) => ({
        ...prevState,
        columns: columns,
      }))
    }
  }

  return (
    <div className='content'>
      <CommonTable
        props={{
          ...tableProps,
          onDelete: getCategoriesAwards,
          onUpdate: getCategoriesAwards,
          onAdd: getCategoriesAwards,
        }}
      ></CommonTable>
    </div>
  )
}
