import { createContext, useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'
import { jwt } from '../../API'
import authApi  from '../../services/auth.service'

export const AuthContext = createContext({
  isAuthenticated: null,
  setIsAuthenticated: null,
  user: null,
  login: () => null,
  logout: () => null,
})

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    // Verificar si el usuario
    // está autenticado y obtener información del usuario

    const fetchUser = async () => {
      try {
        const response = jwt.getTokens()
        const user = await jwtDecode(response.accessToken)
        console.log(user)
        setUser(user)
        setIsAuthenticated(true)
      } catch (error) {
        setIsAuthenticated(false)
      }
    }
    fetchUser()
  }, [])

  const login = async (data) => {
    // API para iniciar sesión
    try {
      const response = await authApi.login(data)
      const decodeToken = await jwtDecode(response.accessToken)
      setUser(decodeToken.user)
      jwt.saveTokens(response.accessToken, response.refreshToken)
      return true
    } catch (error) {
      setIsAuthenticated(false)
      console.log(error)
      throw error
    }
  }

  const logout = () => {
    // Aquí puedes llamar a una API para cerrar sesión
    setUser(null)
    setIsAuthenticated(false)
    jwt.removeTokens()
  }
  const data = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    logout,
    login,
  }
  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>
}
