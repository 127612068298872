import React, { useState } from 'react'
import styles from './PlayContents.module.css'
import '../../../styles/App.css'
import $ from 'jquery'
import { useRef } from 'react'
import { useEffect } from 'react'
const { forwardRef, useImperativeHandle } = React

const PlayContents = forwardRef(({ windowMarkerRef }, ref) => {
  const [visible, setVisible] = useState(false)
  const [externalLng, setExternalLng] = useState(false)
  const [cityInfo, setCityInfo] = useState({})
  const video = useRef(null)

  useEffect(() => {
    if (!cityInfo.audiovisual) return
    if (!visible) {
      video.current.src = ''
      return
    }
    video.current.src = cityInfo.audiovisual
  }, [cityInfo, visible])

  useImperativeHandle(ref, () => ({
    setData: (item) => {
      setCityInfo(item)
    },
    setVisible: (op) => {
      setVisible(op)
      $('#play-contents-main').css('display', 'flex')
    },
    visible: () => visible,
    setExternalLng: (lng) => setExternalLng(lng),
  }))

  const handleClick = () => {
    setVisible(!visible)
    windowMarkerRef.current.setVisible(true)

    if (!visible) {
      setTimeout(function () {
        $('#play-contents-main').css('display', 'none')
      }, 1000)
    }
  }

  return (
    <div id={'play-contents-main'} className={[styles.componentContainer, visible ? 'fadeIn' : 'fadeOut'].join(' ')}>
      <div className={styles.blackBackground} onClick={handleClick}></div>
      <div className={[styles.mainContainer, visible ? 'slideDownFromTop' : 'slideUpFromTop'].join(' ')}>
        <header>
          <h5>{'Contenido de: ' + cityInfo.name}</h5>
          <i style={{ cursor: 'pointer' }} className='bi bi-x' onClick={() => handleClick()}></i>
        </header>
        <div className={styles.containerVideo}>
          <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0' }}>
            <iframe
              ref={video}
              id='video-player'
              title='video-player'
              src={cityInfo.audiovisual}
              allowFullScreen
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
              }}
            ></iframe>
          </div>
        </div>

        {/* <div className={styles.containerContent}>
          <div className={styles.titlesRates}>
            <div>
              <p>
                Copyright: <span>{cityInfo.image_author_manif}</span>
              </p>
              <p>Teacher: Yusim Nacho</p>
              <p>
                <a rel='noreferrer' href={'sadsad'} target={'_blank'}>
                  {' '}
                  Value Chain
                </a>
              </p>
            </div>
            <div>
              <p>Rate</p>
              <p>
                ★★★★★ 5 <i className='bi bi-person'></i> 1
              </p>
              <img src='https://ocitytest.webs.upv.es/assets/creative_commons/yes_yes.png' alt='creative commons'></img>
            </div>
          </div>
          <article>
            <p>
              {externalLng ? cityInfo.manifestation_description_manif : cityInfo.manifestation_description_local_manif}
            </p>
          </article>
        </div>
        <div className={styles.containerBtns}>
          <button onClick={handleClick}>{!externalLng ? 'CERRAR' : 'CLOSE'}</button>
          <button>{!externalLng ? 'REPORTAR (DENUNCIAR)' : 'REPORT (DENOUNCE)'}</button>
        </div> */}
      </div>
    </div>
  )
})

export default PlayContents
