import { ENV } from "../../utils"

export const columns = [
  {
    name: 'Nombre',
    key: 'name',
    filter: 'text',
  },
  {
    name: 'Dirección',
    key: 'direction',
    filter: 'text',
  },
  {
    name: 'Descripción',
    key: 'description',
    filter: 'text',
  },
  {
    name: 'Web',
    key: 'web',
    filter: 'text',
  },
  {
    name: 'Audio Visual',
    key: 'audiovisual',
    filter: 'text',
  },
  {
    name: 'Latitud',
    key: 'latitude',
    filter: 'text',
  },
  {
    name: 'Longitud',
    key: 'longitude',
    filter: 'text',
  },
  {
    name: 'Categoría',
    key: 'category_association.name',
    filter: 'text',
  },
  {
    name: 'Imagen',
    key: 'image',
    as: 'img'
  },
]

export const associations_props = {
  route:`${ENV.BASE_API}/${ENV.API_ROUTES.ASSOCIATIONS}`,
  title: 'Associations',
  entity: 'associations',
  columns: columns,
  actions: ['edit', 'remove'],
  inputAttributes: [
    {
      name: 'Nombre',
      key: 'name',
      required: true
    },
    {
      name: 'Dirección',
      key: 'direction',
      required: false,
    },
    {
      name: 'Descripción',
      key: 'description',
      required: false,
    },
    {
      name: 'Web',
      key: 'web',
      required: false,
    },
    {
      name: 'Redes Sociales',
      key: 'socialNetwork',
      required: false,
    },
    {
      name: 'Audiovisual',
      key: 'audiovisual',
      required: false,
    },
    {
      name: 'Latitud',
      key: 'latitude',
      required: false,
    },
    {
      name: 'Longitud',
      key: 'longitude',
      required: false,
    },
    {
      name: 'Categoría',
      key: 'category_association_id',
      required: false,
    },
  ],
  hasRowSelection: false,
  data: []
}