import React, { createContext } from 'react'
import { useState } from 'react'

export const SearchContext = createContext(null)

export const SearchProvider = ({ children }) => {
  const [search, setSearch] = useState('')
  const [categoryFilter, setCategoryFilter] = useState([])

  return (
    <SearchContext.Provider value={{ search, setSearch, categoryFilter, setCategoryFilter }}>
      {children}
    </SearchContext.Provider>
  )
}
