import jwtDecode from 'jwt-decode'
import axios from 'axios'
import { ENV } from '../utils'

const axiosInstance = axios.create({
  baseURL: `${ENV.BASE_API}/`,
  headers: {
    'Content-type': 'application/json',
  },
})

let refreshTokenPromise // used to queue up requests when refreshing token

axiosInstance.interceptors.request.use(
  async (request) => {
    let token = localStorage.getItem(ENV.JWT.ACCESS)
    if (!token) return request

    const { exp } = jwtDecode(token)

    const currentDate = new Date().getTime()
    const expiredData = new Date(exp * 1000).getTime()

    if (currentDate > expiredData) {
      console.log('TOKEN EXPIRED')
      const refreshToken = localStorage.getItem(ENV.JWT.REFRESH)

      if (!refreshToken) {
        localStorage.removeItem(ENV.JWT.ACCESS)
        localStorage.removeItem(ENV.JWT.REFRESH)
        window.location.href = '/sign-in'
        return Promise.reject('No refresh token found')
      }

      refreshTokenPromise ??= axios.post(
        `${ENV.BASE_API}/auth/refresh`,
        {},
        {
          headers: { 'x-refresh-token': refreshToken, 'x-access-token': token },
        }
      )

      try {
        const { data } = await refreshTokenPromise
        refreshTokenPromise = null
        token = data.accessToken
        localStorage.setItem(ENV.JWT.ACCESS, token)
      } catch (error) {
        console.log('error', error)
        localStorage.removeItem(ENV.JWT.ACCESS)
        localStorage.removeItem(ENV.JWT.REFRESH)
        window.location.href = '/sign-in'
        return Promise.reject(error)
      }
    }

    request.headers.set('x-access-token', token)
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
