import { ENV } from '../utils'

export function saveTokens(access, refresh) {
  localStorage.setItem(ENV.JWT.ACCESS, access)
  localStorage.setItem(ENV.JWT.REFRESH, refresh)
}

function getTokens() {
  const accessToken = localStorage.getItem(ENV.JWT.ACCESS)
  const refreshToken = localStorage.getItem(ENV.JWT.REFRESH)

  return {
    accessToken,
    refreshToken,
  }
}

function removeTokens() {
  localStorage.removeItem(ENV.JWT.ACCESS)
  localStorage.removeItem(ENV.JWT.REFRESH)
}

export const jwt = {
  saveTokens,
  getTokens,
  removeTokens,
}
