import React, { useState } from 'react'

import { theme } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Modal from 'antd/es/modal/Modal'

import UpdateItemForm from '../../UpdateItemForm'
import { useTranslation } from 'react-i18next'
import UserForm from '../../../pages/Users/components/userForm.component'
import CategoriesAwardsForm from '../../../pages/CategoriesAwards/components/categoriesAwardsForm.component'
import CategoriesAssociationsForm from '../../../pages/CategoriesAssociations/components/categoriesAssociationsForm.component'
import AssociationsForm from '../../../pages/Associations/components/associationsForm.component'
import AwardsForm from '../../../pages/Awards/components/awardsForm.component'

const { useToken } = theme
/**
 *
 * @param {*} param0
 * @returns html of a column element
 */
const EditButton = ({ inputAttributes, element, entity, onUpdate }) => {
  const { token } = useToken()
  const { t } = useTranslation()

  // Edit an element modal view
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  function openEditModal() {
    setIsEditModalOpen(true)
  }

  function handleEditCancel() {
    setIsEditModalOpen(false)
  }
  // -------------------------------

  function renderForm(entity) {
    let form
    switch (entity) {
      case 'user': {
        form = (
          <UserForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate && onUpdate()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }
      case 'categoriesAwards': {
        form = (
          <CategoriesAwardsForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate && onUpdate()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }
      case 'categoriesAssociations': {
        form = (
          <CategoriesAssociationsForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate && onUpdate()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }
      case 'associations': {
        form = (
          <AssociationsForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate && onUpdate()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }
      case 'awards': {
        form = (
          <AwardsForm
            element={element}
            isModalOpen={isEditModalOpen}
            onUpdate={() => {
              onUpdate && onUpdate()
              setIsEditModalOpen(false)
            }}
          />
        )
        break
      }
      default: {
        form = <UpdateItemForm inputAttributes={inputAttributes} element={element} />
        break
      }
    }

    return form
  }

  return (
    <>
      <EditOutlined style={{ fontSize: 'large', color: token.colorPrimary }} onClick={openEditModal} />
      <Modal
        width={900}
        footer={null}
        title={t(`Edit ${entity ? entity : 'element'}`)}
        open={isEditModalOpen}
        onCancel={handleEditCancel}
      >
        {renderForm(entity)}
      </Modal>
    </>
  )
}

export default EditButton
