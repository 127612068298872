import React, { useState, useEffect } from 'react';
import { Checkbox, Input } from 'antd';

const SocialMediaSelector = ({ element, title, dataName, setValue, socialMediaOptions }) => {
  const [selectedSocialMedia, setSelectedSocialMedia] = useState([]);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    console.log("inputValuesEFFECT", inputValues);
    setValue(JSON.stringify(inputValues));
  }, [inputValues]);

  useEffect(() => {
    if (element && element.socialNetwork) {
      const socialNetworkData = JSON.parse(element.socialNetwork);
      const selectedSocialMedia = Object.keys(socialNetworkData);
      setSelectedSocialMedia(selectedSocialMedia);
      setInputValues(socialNetworkData);
    }
  }, [element]);

  const handleSocialMediaChange = (e) => {
    const socialMedia = e.target.value;
    setSelectedSocialMedia((prevSelected) => {
      if (prevSelected.includes(socialMedia)) {
        return prevSelected.filter((item) => item !== socialMedia);
      } else {
        return [...prevSelected, socialMedia];
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
    }));
  };

  return (
    <div>
      <p>Selecciona Redes Sociales</p>
      {socialMediaOptions.map((socialMedia) => (
        <div key={socialMedia}>
          <label>
            <Checkbox
              type="checkbox"
              value={socialMedia}
              onChange={handleSocialMediaChange}
              checked={selectedSocialMedia.includes(socialMedia)}
            />{' '}
            {socialMedia}
          </label>
          {selectedSocialMedia.includes(socialMedia) && (
            <Input
              type="text"
              name={socialMedia}
              placeholder={`@${socialMedia}`}
              onChange={handleInputChange}
              value={inputValues[socialMedia] || ''}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SocialMediaSelector;
