import React from 'react'
import { Formik } from 'formik'
import { Typography, Form, Input, Select, Button, Row, Col } from 'antd'
import { Form as AntdForm } from 'antd'
import awardService from '../../../services/award.service'

const { Item } = AntdForm
const { Option } = Select
const { Text } = Typography

const CategoriesAwardsForm = ({ element, isModalOpen, onAdd, onUpdate }) => {
  return (
    <Formik
      initialValues={{
        name: element?.name ?? '',
      }}
      onSubmit={async (values, { resetForm }) => {
        console.log('SUBMIT: ', values)
        try {
          if (element) {
            const response = await awardService.updateCategory(element.id, values)
            alert(response.message === "succesfull update" ? "Actualizado correctamente :)" : "Error en el proceso de actualización");
            onUpdate && onUpdate(response)
          } else {
            const response = await awardService.addCategory(values)
            console.log("RES: ", response)
            alert(response.id ? "Creado correctamente :)" : "Error en el proceso de creación");
            onAdd && onAdd()
            resetForm()
          }
        } catch (error) {
          alert("Error en el proceso")
          console.log(error)
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form>
          <Row gutter={24}>
            <Col xs={24} md={6}>
              <Form.Item
                key={"name"}
                label={"Nombre"}
                name={"name"}
                rules={[{ required: true, }]}>
                <Input
                  id='name'
                  name='name'
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  value={values.name}
                  placeholder='Name'
                />
              </Form.Item>
            </Col>
          </Row>
          <Item>
            <Button type='primary' onClick={handleSubmit} style={{ margin: '10px' }}>
              Submit
            </Button>
          </Item>
        </Form>
      )}
    </Formik>
  )
}

export default CategoriesAwardsForm
