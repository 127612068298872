import React, { useState } from 'react'
import { Formik } from 'formik'
import { Typography, Form, Input, Select, Button, Row, Col } from 'antd'
import { ENV } from '../../../utils'
import { Form as AntdForm } from 'antd'
import Selector from '../../../components/Selector/selector.component'
import SocialMediaSelector from '../../../components/SocialMediaSelector/SocialMediaSelector.component'
import { Divider } from 'antd'
import { InputNumber } from 'antd'
import associationService from '../../../services/association.service'

const { Item } = AntdForm
const { Option } = Select
const { Text } = Typography

const AssociationsForm = ({ element, isModalOpen, onAdd, onUpdate }) => {
  const [form] = Form.useForm()

  return (
    <Formik
      initialValues={{
        name: element?.name ?? '',
        direction: element?.direction ?? '',
        description: element?.description ?? '',
        web: element?.web ?? '',
        socialNetwork: element?.socialNetwork ?? '',
        audiovisual: element?.audiovisual ?? '',
        latitude: element?.latitude ?? 0.0,
        longitude: element?.longitude ?? 0.0,
        category_association_id: element?.category_association_id ?? 1,
        image: null,
      }}
      onSubmit={async (values, { resetForm }) => {
        console.log('SUBMIT: ', values)
        try {
          const formData = new FormData()
          formData.append('name', values.name)
          formData.append('direction', values.direction)
          formData.append('description', values.description)
          formData.append('web', values.web)
          formData.append('socialNetwork', values.socialNetwork)
          formData.append('audiovisual', values.audiovisual)
          formData.append('latitude', values.latitude)
          formData.append('longitude', values.longitude)
          formData.append('category_association_id', values.category_association_id)
          values.image && formData.append('image', values.image)

          if (element) {
            const response = await associationService.updateAssociation(element.id, formData)
            console.log(response)
            alert(
              response.message === 'succesfull update'
                ? 'Actualizado correctamente :)'
                : 'Error en el proceso de actualización'
            )
            onUpdate && onUpdate()
          } else {
            const response = await associationService.addAssociation(formData)
            alert(response.id ? 'Creado correctamente :)' : 'Error en el proceso de creación')
            onAdd && onAdd()
            resetForm()
          }
        } catch (error) {
          alert('Error en el proceso.')
          console.log(error)
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form form={form}>
          <Row gutter={8}>
            <Col xs={24} md={6}>
              <Form.Item
                key={"name"}
                label={"Nombre"}
                name={"name"}
                rules={[{ required: true, },]}>
                <Input
                  id='name'
                  name='name'
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  value={values.name}
                  placeholder='Name'
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                key={"direction"}
                label={"Dirección"}
                name={"direction"}
                rules={[{ required: true, },]}>
                <Input
                  id='direction'
                  name='direction'
                  onChange={(e) => setFieldValue('direction', e.target.value)}
                  placeholder='direction'
                  value={values.direction}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                key={"description"}
                label={"Descripción"}
                name={"description"}
                rules={[{ required: true, }, {
                  validator: async (rule, value) => {
                    if (value && value.length > 255) {
                      return Promise.reject('La descripción no puede exceder los 255 caracteres');
                    }
                  },
                },]}>
                <Input
                  id='description'
                  name='description'
                  onChange={(e) => setFieldValue('description', e.target.value)}
                  placeholder='description'
                  value={values.description}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <label htmlFor='web'>Web</label>
              <Input
                id='web'
                name='web'
                onChange={(e) => setFieldValue('web', e.target.value)}
                value={values.web}
                placeholder='https://google.es'
              />
            </Col>
            <Col xs={24} md={6}>
              <label htmlFor='audiovisual'>Audiovisual</label>
              <Input
                id='audiovisual'
                name='audiovisual'
                onChange={(e) => setFieldValue('audiovisual', e.target.value)}
                placeholder='audiovisual'
                value={values.audiovisual}
              />
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                key={"latitude"}
                label={"Latitud"}
                name={"latitude"}
                rules={[{ required: true, },]}>
                <InputNumber
                  id='latitude'
                  name='latitude'
                  value={values.latitude}
                  onChange={(value) => setFieldValue('latitude', parseFloat(value))}
                  placeholder='Latitude'
                  style={{ width: '100%' }}
                  parser={(value) => (value === '' ? null : parseFloat(value))}
                  formatter={(value) => (value === null ? '' : value.toString())}
                  min={-90} // Valor mínimo permitido para latitud
                  max={90} // Valor máximo permitido para latitud
                  step={0.1} // Incremento o decremento por paso (decimales)
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                key={"longitude"}
                label={"Longitud"}
                name={"longitude"}
                rules={[{ required: true, },]}>
                <InputNumber
                  id='longitude'
                  name='longitude'
                  value={values.longitude}
                  onChange={(value) => setFieldValue('longitude', parseFloat(value))}
                  placeholder='Longitude'
                  style={{ width: '100%' }}
                  parser={(value) => (value === '' ? null : parseFloat(value))}
                  formatter={(value) => (value === null ? '' : value.toString())}
                  min={-180} // Valor mínimo permitido para longitud
                  max={180} // Valor máximo permitido para longitud
                  step={0.1} // Incremento o decremento por paso (decimales)
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Selector
                element={element ?? {}}
                dataName='category_association_id'
                title='Categoría'
                route={`${ENV.BASE_API}/${ENV.API_ROUTES.CATEGORIES_ASSOCIATIONS}`}
                setValue={(val) => setFieldValue('category_association_id', val)}
              />
            </Col>
            <Col xs={24} md={6}>
              <label htmlFor='name'>Seleccion imagen:</label>
              <Input
                id='image'
                type='file'
                name='image'
                extension='.jpg,.jpeg,.png,.ico,.svg'
                onChange={(e) => setFieldValue('image', e.target.files[0])}
              />
            </Col>

            {element?.image && (
              <Col>
                <img src={`${ENV.BACKEND_URL}${element.image}`} alt='Una imagen' width={100} />
              </Col>
            )}

            <Divider />
            <Col xs={24} md={6}>
              <SocialMediaSelector
                element={element ?? {}}
                dataName='socialNetwork'
                title='Redes Sociales'
                setValue={(value) => setFieldValue('socialNetwork', value)}
                socialMediaOptions={['Instagram', 'Twitter', 'Facebook', 'Linkedin']}
              />
            </Col>
          </Row>

          <Item>
            <Button type='primary' onClick={handleSubmit} style={{ margin: '10px' }}>
              Submit
            </Button>
          </Item>
        </Form>
      )}
    </Formik>
  )
}

export default AssociationsForm
