import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { Typography, Form, Input, Select, Button, Row, Col } from 'antd'
import { Form as AntdForm } from 'antd'
import userService from '../../../services/user.service'
import { useState } from 'react'

const { Item } = AntdForm
const { Option } = Select
const { Text } = Typography

const UserForm = ({ element, isModalOpen, onAdd, onUpdate }) => {

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

useEffect(() => {
}, [])


  return (
    <Formik
      initialValues={{
        username: element?.username ?? '',
        name: element?.name ?? '',
        phone: element?.phone ?? '',
        email: element?.email ?? '',
        pswd: element?.pswd ?? '',
        postalCode: element?.postalCode ?? '',
        street: element?.street ?? '',
        locality: element?.locality ?? '',
        country: element?.country ?? '',
        roleName: element?.UserRole?.name ?? '',
        surname: element?.surname ?? '',
      }}
      onSubmit={async (values, { resetForm }) => {
        try {
          if (element) {
            const response = await userService.editUserById(element.id, values)
            alert(response.message === "succesfull update" ? "Actualizado correctamente :)" : "Error en el proceso de actualización");
            onUpdate && onUpdate(response)
          } else {
            const response = await userService.addUser(values)
            alert(response.id ? "Creado correctamente :)" : "Error en el proceso de creación");            onAdd && onAdd(response)
            resetForm()
          }
        } catch (error) {
          alert("Error en el proceso de creación");
          // console.log(error)
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={6}>
              <label htmlFor='username'>Nombre de usuario:</label>
              <Input
                id='username'
                name='username'
                onChange={(e) => setFieldValue('username', e.target.value)}
                placeholder='Username'
                value={values.username}
              />
            </Col>
            <Col xs={24} md={6}>
              <label htmlFor='name'>Nombre:</label>
              <Input
                id='name'
                name='name'
                onChange={(e) => setFieldValue('name', e.target.value)}
                value={values.name}
                placeholder='Name'
              />
            </Col>
            <Col xs={24} sm={12} md={6}>
              <label htmlFor='surname'>Apellidos:</label>
              <Input
                id='surname'
                name='surname'
                onChange={(e) => setFieldValue('surname', e.target.value)}
                placeholder='Surname'
                value={values.surname}
              />
            </Col>

            <Col xs={24} sm={12} md={6}>
              <label htmlFor='phone'>Teléfono:</label>
              <Input
                id='phone'
                name='phone'
                onChange={(e) => setFieldValue('phone', e.target.value)}
                placeholder='Phone'
                value={values.phone}
              />
            </Col>

            <Col xs={24} md={6}>
              <label htmlFor='email'>Email:</label>
              <Input
                id='email'
                name='email'
                onChange={(e) => setFieldValue('email', e.target.value)}
                placeholder='email'
                value={values.email}
              />
            </Col>

            <Col xs={24} md={6}>

              <label htmlFor="pswd">Contraseña:</label>
              <div style={{display:"flex"}}>
                <Input
                  id="pswd"
                  type={showPassword ? 'text' : 'password'}
                  name="pswd"
                  value={values.pswd}
                  onChange={(e) => {setPassword(e.target.value); setFieldValue('pswd', e.target.value) }}
                  placeholder="Contraseña"
                />
                <Button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="eye-button"
                >
                  {showPassword ? (
                    <i className="fas fa-eye-slash"></i> // Cambia el icono según tu elección
                  ) : (
                    <i className="fas fa-eye"></i> // Cambia el icono según tu elección
                  )}
                </Button>
              </div>            
            </Col>

            <Col xs={24} md={6}>
              <label htmlFor='postalCode'>Código postal:</label>
              <Input
                id='postalCode'
                name='postalCode'
                onChange={(e) => setFieldValue('postalCode', e.target.value)}
                placeholder='Postal Code'
                value={values.postalCode}
              />
            </Col>
            <Col xs={24} md={6}>
              <label htmlFor='street'>Calle:</label>
              <Input
                id='street'
                name='street'
                onChange={(e) => setFieldValue('street', e.target.value)}
                placeholder='street'
                value={values.street}
              />
            </Col>

            <Col xs={24} md={6}>
              <label htmlFor='locality'>Localidad:</label>
              <Input
                id='locality'
                name='locality'
                onChange={(e) => setFieldValue('locality', e.target.value)}
                placeholder='locality'
                value={values.locality}
              />
            </Col>

            <Col xs={24} md={6}>
              <label htmlFor='country'>Estado</label>
              <Input
                id='country'
                name='country'
                onChange={(e) => setFieldValue('country', e.target.value)}
                placeholder='country'
                value={values.country}
              />
            </Col>

            <Col xs={24} md={6}>
              <Item label='Rol' name='roleName'>
                <Select onChange={(value) => setFieldValue('roleName', value)} value={values.roleName}>
                  <Option value={'SUPER_ADMIN'}>Super Admin</Option>
                  <Option value={'ADMIN_TECH'}>Admin Tech</Option>
                </Select>
              </Item>
            </Col>
          </Row>
          <Item>
            <Button type="primary" onClick={handleSubmit} style={{ margin: "10px" }}>
              Submit
            </Button>
          </Item>
        </Form>
      )}
    </Formik>
  )
}

export default UserForm
