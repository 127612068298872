import { createContext, useState } from "react";
import enUs from 'antd/locale/en_US'
import esES from 'antd/locale/es_ES'
import { en_US } from "../../utils/lang/en_US";
import { es_ES } from "../../utils/lang/es_ES";
import React from 'react'

export const LocaleContext = createContext({ lang: en_US, antdLang: enUs })
export const LocaleUpdateContext = createContext(() => null)

export function LocaleProvider({ children }) {
    const [currLang, setLang] = useState({ lang: en_US, antdLang: enUs })

    function changeLanguage(newLang) {
        switch (newLang) {
            case 'es_ES':
                setLang({ lang: es_ES, antdLang: esES })
                break;
        
            default:
                setLang({ lang: en_US, antdLang: enUs })
                break;
        }
    }

    return (
        <LocaleContext.Provider value={currLang}>
            <LocaleUpdateContext.Provider value={changeLanguage}>
                {children}
            </LocaleUpdateContext.Provider>
        </LocaleContext.Provider>
    )
}