import React, { useEffect, useState } from 'react'
import styles from './WindowMarker.module.css'
import '../../../styles/App.css'
import $ from 'jquery';
import { ENV } from '../../../utils';
import associationService from '../../../services/association.service';
import award_logo_default from '../../../assets/awards.png';
import { Modal, Row, Col } from 'antd';

const { forwardRef, useImperativeHandle } = React;

const WindowMarker = forwardRef(({ playContentsRef }, ref) => {

    const [isModalAwardVisible, setIsModalAwardVisible] = useState(false);
    const [modalAwardInfo, setModalAwardInfo] = useState('');

    const [associationInfo, setAssociationInfo] = useState({});
    const [awardsInfo, setAwardsInfo] = useState([]);
    const [visible, setVisible] = useState(false);
    const [readMore, setReadMore] = useState(false);
    const [externalLng, setExternalLng] = useState(false);

    const openModal = (award) => {
        setModalAwardInfo(award);
        setIsModalAwardVisible(true);
    };

    const closeModal = () => {
        setIsModalAwardVisible(false);
    };
    useImperativeHandle(ref, () => ({
        setData: async (item) => {
            setAssociationInfo(item)
            try {
                console.log("item", item)
                const awards = await associationService.getAssociationsAwards(item.id)
                setAwardsInfo(awards)
                console.log(awards)
            } catch (err) {
            }
        },
        setVisible: (op) => {
            setVisible(op);
            $('#window-marker').css('display', 'block');
        },
        visible: () => visible
    }));

    const handleClick = () => {
        setVisible(!visible);
        if (!visible) {
            setTimeout(function () {
                $('#play-contents-main').css('display', 'none');
            }, 1000);
        }
    }
    const displayContents = () => {
        playContentsRef.current.setData(associationInfo);
        if (!playContentsRef.current.visible()) {
            playContentsRef.current.setVisible(true);
        }
    }

    return (
        <div id={'window-marker'} className={[styles.mainContainer, visible ? 'fadeIn' : 'fadeOut'].join(' ')} >
            <header>
                <h5>{associationInfo?.name ? associationInfo.name : 'Sin Nombre'}</h5><i style={{ cursor: 'pointer' }} className='bi bi-x' onClick={() => handleClick()}></i>
            </header>
            <div className={styles.containerContents}>
                <div>
                    <img style={{ maxWidth: '100%', maxHeight: '200px', objectFit: 'cover', }} src={associationInfo?.image ? `${ENV.BACKEND_URL}/${associationInfo.image}` : 'https://pasalabola.org/wp-content/uploads/2018/10/PLB-icono.png'} alt="imagen" />
                </div>
                <div>
                    <button onClick={displayContents}>{!externalLng ? 'Ver Contenido' : 'Play Contents'}</button>
                </div>
            </div>
            <div className={styles.infoCity}>
                <p>Dirección: {associationInfo.direction}</p>
            </div>
            <div className={styles.infoCity}>
                <p>Descripción: {associationInfo.description}</p>
            </div>
            <div className={styles.awards}>
                <p>Premios:</p>
                {
                    awardsInfo?.map((award) => {
                        return (<>
                            <img
                                width="50px"
                                src={award?.image ? `${ENV.BACKEND_URL}/${award.image}` : award_logo_default}
                                onClick={() => openModal(award)}
                                alt="imagen"
                                style={{ cursor: "pointer" }} />
                        </>
                        )
                    })
                }
            </div>
            {/* <div className={styles.interestLink}>
                <p> Link of interest:<a rel="noreferrer" href={city_networks[0]?.url ? city_networks[0].url : 'Sin Nombre'} target={'_blank'}> {city_networks[0]?.name ? city_networks[0].name : 'Sin Nombre'}</a></p>
            </div> */}
            <div className={styles.shareBtnContainer}>
                {
                    associationInfo?.socialNetwork?.Instagram ?
                        <i className='bi bi-instagram' onClick={() => { window.open("https://instagram.com/" + associationInfo.socialNetwork.Instagram )}}></i>
                        : <></>
                }
                {
                    associationInfo?.socialNetwork?.Facebook ?
                        <i className='bi bi-facebook' onClick={() => { window.open("https://facebook.com/" + associationInfo.socialNetwork.Facebook )}}></i>
                        : <></>
                }
                {
                    associationInfo?.socialNetwork?.Twitter ?
                        <i className='bi bi-twitter' onClick={() => { window.open("https://twitter.com/" + associationInfo.socialNetwork.Twitter) }}></i>
                        : <></>
                }
                {
                    associationInfo?.socialNetwork?.Linkedin ?
                        <i className='bi bi-linkedin' onClick={() => { window.open("https://linkedin.com/" + associationInfo.socialNetwork.linkedin )}}></i>
                        : <></>
                }
                <div>
                    {
                        associationInfo?.web !== "" ?
                            <i className='bi bi-link-45deg' onClick={() => { window.open(associationInfo.web )}}></i>
                            : <></>
                    }
                </div>
            </div>

            <Modal
                visible={isModalAwardVisible}
                onCancel={closeModal}
                footer={null}
                width={600} // Ancho personalizado para el modal
            >
                <Row gutter={16}> {/* Espaciado entre las columnas */}
                    <Col span={12}>
                        <img
                            src={modalAwardInfo.image ? `${ENV.BACKEND_URL}/${modalAwardInfo.image}` : award_logo_default}
                            alt="imagen"
                            style={{ width: '100%' }} // Asegura que la imagen ocupe todo el espacio de la columna
                        />
                    </Col>
                    <Col span={12}>
                        <h2>Premio:  {modalAwardInfo.name}</h2>
                        <p>Fecha de entrega: {modalAwardInfo.deliveryDate}</p>
                        <p>Identidad creadora: {modalAwardInfo.creativeIdentity}</p>
                        <p>Fecha de creación: {modalAwardInfo.createdAt}</p>
                        <p>Fecha de actualización: {modalAwardInfo.updatedAt}</p>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
})

export default WindowMarker;
