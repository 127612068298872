export const es_ES = {
    "Email address": "Correo electrónico",
    "Password": "Contraseña",
    "Rememeber me": "Recuerdame",
    "Description": "Descripción",
    "Name": "Nombre",
    "Sumbit": "Aceptar",
    "Home": "Inicio",
    "Calendar": "Calendario",
    "Request": "Petición",
    "Alarm": "Alarma",
    "Alarms": "Alarmas",
    "Status": "Estado",
    "History": "Historial",
    "General": "General",
    "Devices": "Dispositivo",
    "Map": "Mapa",
    "Are you sure you want to remove this element?": "¿Estás seguro de que quieres eliminar este elemento?",
    "New": "Nuevo",

    // DASHBOARD
    "Information": "Información",
    // MYPROFILE
    "Phone" : "Teléfono",
    "Postal Code" : "Código Postal",
    "Street" : "Calle",
    "City" : "Ciudad",
    "State" : "Comunidad",
    "Country" : "País",
    "Address" : "Dirección",
    "Last Name" : "Apellido",
    "Username" : "Nombre de usuario",
    // Modals
    "Edit Element": "Editar Elemento",
    "Message info": "Info del Mensaje",
    "Message": "Mensaje",
    "Delete Element": "Borrar Elemento",
    "Add Element": "Añadir Elemento",
    "Redirect To Other Web": "¿Estás seguro que deseas ser redirigido a otra página web?",
    "Support confirmation": "¿Estás seguro que deseas enviar este mensaje?",
    "Cancel": "Cancelar",
    "Accept": "Aceptar",
    "Confirmation": "Confirmación",

    //Left  Menu
    "Users": "Usuarios",
    "User": "Usuario",
    "City": "Ciudad",
    "Cities": "Ciudades",
    "Ambassador": "Embajador",
    "Ambassadors": "Embajadores",
    "Heritage": "Patrimonio",
    "Roads": "Carreteras",
    "Learn": "Aprender",
    "About": "Sobre OCITY",
    "Blog": "Blog",
    "Support": "Soporte",
    //Navbar
    "Login": "Inicar sesión",
    //Users
    "Country": "País",
    "Role": "Rol",
    "Surname": "Apellido",
}