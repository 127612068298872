export const en_US = {
    "Email address": "Email address",
    "Password": "Password",
    "Rememeber me": "Remember me",
    "Description": "Description",
    "Name": "Name",
    "Sumbit": "Submit",
    "Home": "Home",
    "Calendar": "Calendar",
    "Request": "Request",
    "Alarm": "Alarm",
    "Alarms": "Alarms",
    "Status": "Status",
    "History": "History",
    "General": "General",
    "Devices": "Devices",
    "Map": "Map",
    "Are you sure you want to remove this element?": "Are you sure you want to remove this element?",
    "New": "New",
    
    // DASHBOARD
    "Information": "Information",
    // MYPROFILE
    "Phone" : "Phone",
    "Postal Code" : "Postal Code",
    "Street" : "Street",
    "City" : "City",
    "State" : "State",
    "Country" : "Country",
    "Address" : "Address",
    "Street" : "Street",
    "Last Name" : "Last Name",
    "Username" : "Username",
    // Modals
    "Edit Element": "Edit Element",
    "Message info": "Message Info",
    "Message": "Message",
    "Delete Element": "Delete Element",
    "Add Element": "Add Element",
    "Users": "Users",
    "User": "User",
    "City": "City",
    "Cities": "Cities",
    "Ambassador": "Ambassador",
    "Ambassadors": "Ambassadors",
    "Heritage": "Heritage",
    "Roads": "Roads",
    "Learn": "Learn",
    "About": "About OCITY",
    "Blog": "Blog",
    "Support": "Support",
    "Redirect To Other Web": "Are you sure you want to be redirected to another website?",
    "Support confirmation": "Are you sure you want to send this message?",
    "Cancel": "Cancel",
    "Accept": "Accept",
    "Confirmation": "Confirmation",

    //Navbar
    "Login": "Login",
    //Users
    "Country": "Country",
    "Role": "Role",
    "Surname": "Surname",
    }